import React , { useState } from 'react';
import {AnimatePresence, motion, useScroll} from "framer-motion/dist/framer-motion";

import SectionTitle from "../../elements/sectionTitle/SectionTitle";

const ModalApp = ({show, setShow , text , setText,  value}) => {
const [isHovered, setIsHovered] = useState(false);

const hoveritem = {

  initial: {
    opacity: 1,
    pathLength: 0,
    stroke: "rgba(255,255,255,0)"
  },
  hover: {
    opacity: 1,
    pathLength: 1,
    stroke: "rgba(255,255,255,.8)",
    transition: {
          pathLength: { duration: 3, ease: "easeInOut"  },
          stroke: { duration: 1, ease: "easeInOut"  },
      }
      },
      initialb: {
            stroke: "rgba(255,255,255,0)",
    opacity: 1,
    pathLength: 1,
   background: "rgba(255,255,255,.5)",

  },
  hoverb: {
    stroke: "rgba(255,255,255,.8)",
    opacity: 1,
    background: "rgba(0,0,0,.9)",
    transition: {
          background: { duration: .5, ease: "easeInOut"  },
      }
 }
      ,
      initialbb: {
            stroke: "rgba(255,255,255,0)",
    opacity: 1,
    pathLength: 1,
   background: "rgba(0,0,0,0)",

  },
  hoverbb: {
    stroke: "rgba(255,255,255,.8)",
    opacity: 1,
    background: "rgba(0,0,0,.9)",
    transition: {
          background: { duration: .5, ease: "easeInOut"  },
      }
  } ,
      initiali: {
            stroke: "rgba(255,255,255,0)",
    opacity: 0,
    

  },
  hoveri: {
    opacity: 1,
    transition: {
          opacity: { duration: .5, ease: "easeInOut"  },
      }
  }
};

  
const handleContents = () => { 
  setShow(!show);
  if (value === 'FRONT END') {
    setText('FRONT END');
  }
  else if (value === 'BRANDING') {
    setText('BRANDING');
  }
  else if (value === 'DISEÑO UI UX') {
    setText('DISEÑO UI UX');
  }
    else if (value === 'SOCIAL MEDIA') {
      setText('SOCIAL MEDIA');
  }
   console.log("sajdhhbasdjb" , show);
}

  function handleMouseEnter() {
    setIsHovered(true);
  }

  function handleMouseLeave() {
    setIsHovered(false);
  }
  

  return (
    <>
      <div className="grid" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleContents}>
        <motion.div 
          variants={hoveritem}
          initial="hidden"
          animate={isHovered ? "hoverb" : "initialb"}
          className="back-item-s back-item-sf" >          
        </motion.div>

        <motion.svg   viewBox="0 0 170.52 170.52" className="back-item-s" >
          <motion.path
            className="cls-ab" 
            variants={hoveritem}
            initial="hidden"
            animate={isHovered ? "hover" : "initial"}
            d="M85.26,170.52C38.17,170.52,0,132.34,0,85.26S38.17,0,85.26,0s85.26,38.17,85.26,85.26-38.17,85.26-85.26,85.26Zm73.83-42.64L85.26,0,11.43,127.88H159.09Zm8.51-64.69L24.97,24.97,63.19,167.6,167.6,63.19ZM127.88,11.43L0,85.26l127.88,73.83V11.43ZM63.19,2.91L24.97,145.54l142.63-38.22L63.19,2.91ZM11.43,42.64l73.83,127.88L159.09,42.64H11.43ZM2.91,107.33l142.63,38.22L107.33,2.91,2.91,107.33Zm39.72,51.76l127.88-73.83L42.64,11.43V159.09Zm64.69,8.51L145.54,24.97,2.91,63.19l104.41,104.41Z"/>
        </motion.svg>
            
        {value === "FRONT END" && (
          <motion.svg viewBox="0 0 81.6 92.5" x="0px" y="0px" className="icon1" data-name="Layer 1" width="100%" height="100%"xmlns="http://www.w3.org/2000/svg" >
            <motion.path variants={hoveritem}
              initial="hidden"
              animate={isHovered ? "hoverbb" : "initialbb"}
              data-ison={isHovered}
              className="st0" d="M16.2,62.1l26.1-45.2l26.1,45.2H16.2z M81.6,70.1L42.3,92.8L3,70.1V24.7L42.3,2l39.3,22.7V70.1z M68.4,32.5 L42.3,77.7L16.2,32.5H68.4z M3,69.9l39.3,7.8l39.3-7.8L68.4,32.5L42.3,2L16.2,32.5L3,69.9z M81.6,24.6l-39.3-7.7L3,24.7l13.2,37.4 l26.1,30.4l26.1-30.4L81.6,24.6z M68.4,32.5c8.1-4.7,5.3-3.3,13.2-7.9 M3,69.9c13.1-7.6,0-0.1,13.1-7.6 M16.3,32.2 c-8.2-4.6-5.3-3-13.3-7.5 M42.3,92.6c0-15.2-0.1-0.1,0-15.2 M42.3,17.2c0-15.2-0.1-0.1,0-15.2 M68.4,62.1l13.2,7.8"/>
          </motion.svg>
        )}
        {value === "BRANDING" && (
          <motion.svg viewBox="0 0 81.6 92.5" x="0px" y="0px" className="icon1" data-name="Layer 1" width="100%" height="100%"xmlns="http://www.w3.org/2000/svg" >
            <motion.path variants={hoveritem}
              initial="hidden"
              animate={isHovered ? "hoverbb" : "initialbb"}
              data-ison={isHovered}
              className="st0" 
              d="M48.8,41.6l-8,4.5l-8-4.5l4-2.3l4-2.3l4.1,2.3L48.8,41.6z M64.9,32.4l-7.3,4.1l-8.4-4.8l-8.4-4.9l-8.4,4.9
                  l-8.3,4.8l-7.3-4.1l12-6.9l12-7l12.1,7L64.9,32.4z M80.9,23.1l-8,4.6l-16-9.2L40.8,9.2l-16,9.3l-16,9.2l-8-4.6l20-11.5L40.8,0
                  l20.1,11.6L80.9,23.1z M40.8,55.6l-4-2.3l-4.1-2.4v-4.6v-4.7h0.1l8,4.5V55.6z M40.8,46.1L40.8,46.1l8-4.5l0.1,4.7v4.6l-4,2.4
                  l-4.1,2.3V46.1z M40.8,74l-12-6.9l-12-7V46.3V32.4l7.3,4.1L24,46.3V56l8.4,4.8l8.4,4.9V74z M40.8,92.5l-20-11.6L0.7,69.4V46.3
                  l0.1-23.2l8,4.6v18.6v18.4l16,9.3l16,9.2V92.5z M40.8,83.2L56.9,74l16-9.3V46.3V27.7l8-4.6v23.2v23.1l-20,11.5L40.8,92.5V83.2z
                   M40.8,74l12-6.9l12.1-7V46.3l-0.1-13.9l-7.2,4.1l0.1,0.1v9.7V56l-8.5,4.8l-8.4,4.9V74z" />       
          </motion.svg>
        )}
        {value === "DISEÑO UI UX" && (
          <motion.svg viewBox="0 0 81.6 92.5" x="0px" y="0px" className="icon1" data-name="Layer 1" width="100%" height="100%"xmlns="http://www.w3.org/2000/svg" >
            <motion.path variants={hoveritem}
              initial="hidden"
              animate={isHovered ? "hoverbb" : "initialbb"}
              data-ison={isHovered}
              className="st0" 
              d="M14.6,79.2L-1,31.3L39.8,1.7l40.8,29.6L65,79.2H14.6z M60.1,16.6l12.5,38.5L39.8,78.9L7,55.1l12.5-38.5H60.1z
                 M23.6,66.9l-10-30.8l26.2-19l26.2,19L56,66.9H23.6z M52.8,26.7l8,24.7l-21,15.2l-21-15.2l8-24.7H52.8z M29.5,58.8l-6.3-19.6
                l16.6-12l16.6,12l-6.3,19.6H29.5z M48.1,33.3l5,15.6l-13.3,9.7l-13.3-9.7l5-15.6H48.1z M33.2,53.7l-4.1-12.5l10.7-7.7l10.7,7.7
                l-4.1,12.5H33.2z M45.1,37.4l3.3,10l-8.6,6.2l-8.6-6.2l3.3-10H45.1z" />       
          </motion.svg>
        )}
        {value === "SOCIAL MEDIA" && (
          <motion.svg viewBox="0 0 81.6 92.5" x="0px" y="0px" className="icon1" data-name="Layer 1" width="100%" height="100%"xmlns="http://www.w3.org/2000/svg" >
            <motion.path variants={hoveritem}
              initial="hidden"
              animate={isHovered ? "hoverbb" : "initialbb"}
              data-ison={isHovered}
              className="st0" 
              d="M73.5,52.7l6.9-13.1l0.4,15.2L74,68.1L73.5,52.7z M66.7,39.3L73.5,26l6.9,13.6l-6.9,13.1L66.7,39.3z M54.8,31.8
                l6.4-13.5l12.2,7.5l0.1,0.2l-6.8,13.3L54.8,31.8z M61.2,18.3L54.5,4.9l12.2,7.6l6.7,13.3L61.2,18.3z M60.1,68.2v-15l13.4-0.5
                L74,68.1l-0.1,0.2L60.1,68.2z M47.1,35.6l-0.2-0.1c-0.7-0.5-3.7-2.5-5.1-3.4l-0.5-0.3l13.5,0.1l11.9,7.5l-13.5,0.4L47.1,35.6z
                 M53.2,81.4l6.9-13.2l13.8,0.1l-7,13.5L53.2,81.4z M53.2,39.7l13.5-0.4l6.8,13.4l-13.3,0.5L53.2,39.7z M41.3,31.7l-0.1-0.1v-0.1
                l6.4-13.3l13.6,0.1l-6.4,13.5L41.3,31.7z M47.6,18.2L40.5,5l14-0.1l6.7,13.4L47.6,18.2z M53,55.2v-8.1l0,0c0-1,0.2-7.4,0.2-7.4
                l6.9,13.5L60,68.3L53,55.2z M46,68.5l7-13.3l7.1,13l-6.9,13.2L46,68.5z M53.2,81.4l13.7,0.4l-11.6,7.8l-14.2,0.1L53.2,81.4z
                 M28.8,13.1L40.5,5l7.1,13.2l-11.5,7.6L28.8,13.1z M36,25.9l11.6-7.7l-6.4,13.4h-0.1l-6,3.8l-6,3.7L36,25.9z M40.9,62.8l5.9-3.9l0,0
                c0.4-0.2,3.8-2.3,5.6-3.3l0.5-0.3l-7,13.3l-11.6,7.6L40.9,62.8z M14.8,13l12-7.9L40.5,5l-11.7,8.1L14.8,13z M34.4,76.1L46,68.5
                l7.2,12.9l-11.9,8.2L34.4,76.1z M22,26.5l6.8-13.4l7.3,12.7L36,25.9l-6.9,13.2L22,26.5z M22.2,41.7L22,26.5l7.1,12.6L28.9,47
                l0.1,7.9L22.2,41.7z M14.8,13l14,0.1L22,26.5H7.7L14.8,13z M20.6,76l13.8,0.1l6.9,13.6L27,89.9L20.6,76z M15.4,55.5L29,55l5.8,3.8
                l0,0c0.2,0.1,0.7,0.5,2.5,1.6c0,0,2.5,1.6,3.1,2.1l0.5,0.3l-13.8,0.1L15.4,55.5z M8.6,42.4l13.6-0.7L29,54.9l-13.6,0.5L8.6,42.4z
                 M20.6,76l6.6-13.2h13.7l-6.5,13.3L20.6,76z M8.3,68.6l7.1-13.1l11.8,7.3L20.6,76L8.3,68.6z M7.7,26.5H22l0.2,15.2L8.6,42.4
                L7.7,26.5z M15.2,82.3L8.3,68.6L20.6,76L27,89.9L15.2,82.3z M1.4,55l7.2-12.6l6.8,13L8.3,68.7L1.4,55z M1,39.1l6.7-12.6l0.9,15.9
                L1.4,55L1,39.1z"/>        
          </motion.svg>
        )}
      
        <motion.h2  
        variants={hoveritem}
        initial="hidden"
        animate={isHovered ? "hoveri" : "initiali"} 
        className="text-item"
        >
          <SectionTitle
            textAlign = "text-center"
            radiusRounded = ""
            subtitle = {value}
            title = ""
            description = ""
          />
        </motion.h2>

      </div>
    </> 
  ); 
};



export default ModalApp;