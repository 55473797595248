import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionOne = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    ¿Como manejo <strong>mi plataforma</strong>? ¿Tengo que saber de programación ?                
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>No necesitas conocimientos <strong>de programación</strong> ya que manejamos<strong> gestores de contenido</strong>, que te darán acceso al administrador de tus blogs,<strong> productos</strong>, ventas, tus Sliders Promocionales, chats, etc.. Asi que te dejamos con el suficiente conocimiento para hacerte cargo de tu proyecto. </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    Al terminar ¿tengo que pagar Alguna mensualidad?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>Al terminar <strong>el proyecto es tuyo </strong>y lo puedes desplegar donde quieras, nuestros <strong>desarrollos web</strong> estan hechos bajo programación estándar y cms conocidos como <strong>wordpress</strong>, que cualquier administrador de contenido conoce, asi que puedes seguir creciendo con nosotros o con ayuda de terceros.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    ¿ Mi <strong>pagina web </strong>tambien sirve para celulares?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>Manejamos <strong>Diseño Responsive</strong>, lo que significa que la<strong> Pagina Web</strong> o <strong>Plataforma web </strong>viene optimizada para Celulares, Tablets, Computadores de escritorio y portátiles, con<strong> diseños adaptados </strong>para cada uno y pensando en el usuario final. </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    ¿Como recibo los pagos de mis clientes?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>Manejamos Pasarelas de pago que serán administradas por ti ( Payu -Mercadopago -Paypal etc.. ) Que ofrecen <strong>todo tipo de pagos</strong>, Tarjetas de credito - debito, Baloto, medios físicos. Asi cada pago ira directo a tu cuenta.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                    Quiero Conectar mi<strong> Tienda virtual </strong>con mi <strong>ERP </strong>¿ Se Puede ?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                    <Card.Body>Tenemos basta experiencia de<strong> conexiones Erp </strong>con <strong>proyectos de cms o ala medida</strong>, para que tus <strong>tiendas virtuales</strong> o <strong>plataformas  Web </strong>esten sincronizadas con tus gestores administrativos 
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

        </Accordion>
    )
}
export default AccordionOne

