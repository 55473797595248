import React from 'react';
import Typed from 'react-typed';

const MarketTwo = () => {
    return (
        <div className="rwt-about-area rn-section-gap back-white">
            <div className="container">
                <div className="row row--30 align-items-center">
                <div className="col-lg-6">
                        <div className="thumbnail">
                            <img className="w-100" src="art4.jpg" alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-6 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title color-black">¡Hola Mundo! <br /> {" "}
                                    <span className="theme-gradient">
                                        Publicidad Btl + Atl
                                    </span>
                                </h2>
                                 <p> <strong>Manejo de marca</strong>, acciones con tu<strong> línea gráfica</strong>, <strong>desarrollo de piezas</strong> para manejo de productos y de <strong>imagen corporativa en Bogotá</strong>, creación de<strong> logotipos</strong>, actualización de marcas, <strong>Papelería en todos sus formatos</strong>, <strong>mailing Bogotá</strong>, <strong>Presentaciones web</strong> o <strong>presentaciones corporativas</strong>, Video interview, <strong>apps Corporativas</strong>, la imagen como primer medio. </p>
         <div className="read-more-btn mt--40">
                                     <a className="btn-default" href="https://kingart.com.co/world/tienda/" target="_blank"><span>¡Contacto!</span></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    

                    
                </div>
            </div>   
        </div>
    )
}

export default MarketTwo;   
