import  React , { useState, useRef } from 'react';
import Typed from 'react-typed';
import {AnimatePresence, motion , useMotionValue, useTransform } from "framer-motion/dist/framer-motion"; 

import SectionTitle from "../sectionTitle/SectionTitle";
import Sacrilege from "./Sacrilege";
import ItemPortfolio from "./ItemPortfolio";


function getRelativeCoordinates(event, referenceElement) {
  const position = {
    x: event.pageX,
    y: event.pageY
   };

  const offset = {
    left: referenceElement.offsetLeft,
    top: referenceElement.offsetTop,
    width: referenceElement.clientWidth,
    height: referenceElement.clientHeight
  };

  let reference = referenceElement.offsetParent;

  while (reference) {
    offset.left += reference.offsetLeft;
    offset.top += reference.offsetTop;
    reference = reference.offsetParent;
  }

  return {
    x: position.x - offset.left,
    y: position.y - offset.top,
    width: offset.width,
    height: offset.height,
    centerX: (position.x - offset.left - offset.width / 2) / (offset.width / 2),
    centerY: (position.y - offset.top - offset.height / 2) / (offset.height / 2)
  };
}

const Portafolio = () => {

    const x = useMotionValue(0);
    const y = useMotionValue(0);


    const [mousePosition, setMousePosition] = useState({});
    const boxRefp = useRef();

    const rotateX = mousePosition.x;
    const rotateY = mousePosition.y;
    const rotateXd = mousePosition.width;
    const rotateXdd =  useTransform(x, [0, rotateXd], [5, -5]);
    const rotateYdd =  useTransform(y, [0, rotateXd], [-5, 5]);
    const rotateXddp =  useTransform(x, [0, rotateXd], [-13, 13]);
    const rotateYddp =  useTransform(y, [0, rotateXd], [13, -13]);
    const handleMouseMove = e => {
        setMousePosition(getRelativeCoordinates(e, boxRefp.current));
        x.set(rotateX);
        y.set(rotateY);
    };
    return (
        <section className="portfoliot"  onMouseMove={e => handleMouseMove(e)} ref={boxRefp}>
 

             <Sacrilege negxp={rotateXdd} negyp={rotateYdd} posx={rotateXddp} posy={rotateYddp}/>
             <div className="content-portfolioi"> 
                <ItemPortfolio value="DISEÑO UI/IX" />
                <ItemPortfolio value="BRANDING" />
                <ItemPortfolio value="FRONT END" />
                <ItemPortfolio value="MARKETING" />
             </div>

        </section>
    )
}

export default Portafolio;   