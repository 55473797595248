import React , { useState } from 'react';
import HeaderTwo from '../common/header/HeaderTwo';
import HeaderTopNews from "./header/HeaderTopNews";
import FooterTwo from "./footer/FooterTwo";
import Copyright from "./footer/Copyright";

const Layout = ({children}) => {
    const [ofcanvasShow, setOffcanvasShow] = useState(false);

    return (
        <>
            <main className="page-wrapper">
                <HeaderTopNews />
                <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" setOffcanvasShow={setOffcanvasShow } ofcanvasShow={ofcanvasShow} />
                {children}
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}
export default Layout;
