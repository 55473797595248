import React from 'react';
import Typed from 'react-typed';

const WebThree = () => {
    return (
        <div className="rwt-about-area rn-section-gap back-white">
            <div className="container">
                <div className="row row--30 align-items-center">

                    <div className="col-lg-5 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title color-black">Ui - Ux - Developer<br /> {" "}
                                    <span className="theme-gradient">
                                        Plataformas para todo tipo de usuarios  
                                    </span>
                                </h2>
                                
                                <p>Planeo y diseño con propósito, cada botón, cada acción con un objetivo,  datos de relevancia para superar los retos, atrapar al usuario y cautivarlo  </p>
                                
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-7">
                        <div className="thumbnail">
                            <img className="w-100" src="design-222.jpg" alt="About Images" />
                        </div>
                    </div>

                    
                </div>
            </div>   
        </div>
    )
}

export default WebThree;   
