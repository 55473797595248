import  React , { useState, useRef} from 'react';
import { Link } from 'react-scroll';
import {AnimatePresence, motion, useScroll} from "framer-motion/dist/framer-motion";

const hovernerv = {

  initial: {
    opacity: 0,
    pathLength: 0,
  },
  hover: {
    opacity: 1,
    pathLength: 1,
    transition: {
          pathLength: { duration: 2, ease: "easeInOut"  },
      }
      },
      

};


const Nerv = ({ textt , setTextt , textw , nup , ndown}) => {
    return (
        <div className="nerv">
            <Link className="top-one" id="" value={textt} name={textw} to={nup} spy={true} smooth={true} duration={500} >
              <motion.svg id="" viewBox="0 0 35 47">
                <motion.path variants={hovernerv} initial="initial" animate="hover" className="ncls-3" d="m6.24,19.59s0-.02,0-.03L17.06,1.03s.03-.03.05-.03.04.01.05.03l10.65,18.63s0,.04,0,.06c-.01.02-.03.03-.05.03l-21.46-.09s-.04-.01-.05-.03c0,0,0-.02,0-.03ZM17.1,1.17L6.39,19.54l21.26.09L17.1,1.17Z"/>
                <motion.path variants={hovernerv} initial="initial" animate="hover" className="ncls-3" d="m6.24,27.46s0-.02,0-.03l10.81-18.54s.03-.03.05-.03.04.01.05.03l10.65,18.63s0,.04,0,.06c-.01.02-.03.03-.05.03l-21.46-.09s-.04-.01-.05-.03c0,0,0-.02,0-.03Zm10.87-18.42L6.39,27.4l21.26.09-10.55-18.46Z"/>
                <motion.path variants={hovernerv} initial="initial" animate="hover" className="ncls-2" d="m17.45,10.45c9.36,0,16.98,7.62,16.98,16.98s-7.62,16.98-16.98,16.98S.46,36.8.46,27.44,8.08,10.45,17.45,10.45Z"/>
              </motion.svg>
             </Link>
            <Link className="down-one" id="" value={textt} name={textw} to={ndown} spy={true} smooth={true} duration={500} >

                <motion.svg id="" viewBox="0 0 35 47">
                <motion.path variants={hovernerv} initial="initial" animate="hover" className="ncls-3" d="m6.24,19.59s0-.02,0-.03L17.06,1.03s.03-.03.05-.03.04.01.05.03l10.65,18.63s0,.04,0,.06c-.01.02-.03.03-.05.03l-21.46-.09s-.04-.01-.05-.03c0,0,0-.02,0-.03ZM17.1,1.17L6.39,19.54l21.26.09L17.1,1.17Z"/>
                <motion.path variants={hovernerv} initial="initial" animate="hover" className="ncls-3" d="m6.24,27.46s0-.02,0-.03l10.81-18.54s.03-.03.05-.03.04.01.05.03l10.65,18.63s0,.04,0,.06c-.01.02-.03.03-.05.03l-21.46-.09s-.04-.01-.05-.03c0,0,0-.02,0-.03Zm10.87-18.42L6.39,27.4l21.26.09-10.55-18.46Z"/>
                <motion.path variants={hovernerv} initial="initial" animate="hover" className="ncls-2" d="m17.45,10.45c9.36,0,16.98,7.62,16.98,16.98s-7.62,16.98-16.98,16.98S.46,36.8.46,27.44,8.08,10.45,17.45,10.45Z"/>
              </motion.svg>
             </Link>
        </div>
    )
};


export default Nerv;