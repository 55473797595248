import React from 'react';
import Typed from 'react-typed';

const AppThree = () => {
    return (
        <div className="rwt-about-area back-white rn-splite-style">
            <div className="split-wrapper">
                <div className='container'>
                    <div className="row row--30 align-items-center">
                        <div className="col-lg-6 mt_md--40 mt_sm--40">
                            <div className="content split-inner">
                                <div className="section-title">
                                    <h2 className="title color-black">¡Herramientas que duran! <br /> {" "}
                                        <span className="theme-gradient">
                                            Mejores Tecnologías
                                        </span>
                                    </h2>
                                    <p>Nuestro equipo de desarrollo toma como base las nuevas tendencias de estructura y lenguajes para creación de plataformas web, Que permitan plataformas modulares y de alta escalabilidad, que proporcionen entornos ligeros de poca carga pero potentes en sus procesos,<strong> Desarrollo de Plataformas</strong> híbridas y nativas.</p>
                                                <img className="w-50" src="lenguajes-king-art.png" alt="About Images" />
                                    <div className="read-more-btn mt--40">
                                        <a className="btn-default" href="https://kingart.com.co/world/tienda/" target="_blank"><span>¡Contacto!</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="thumbnail image-rigth-content">
                                <img className="w-100" src="platform-444.jpg" alt="About Images" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AppThree;   
