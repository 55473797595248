import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
    {
        id: "1",
        title: "La Idea",
        description: "Escuchamos tu idea, revisamos el mercado, evaluamos todos los detalles. ",
    },
    {
        id: "2",
        title: "Que Plataforma ",
        description: "Concatenamos tus necesidades con las herramientas necesarias y planeamos.",
    },
    {
        id: "3",
        title: "Es Real ",
        description: "Diseñamos y Desarrollamos la plataforma teniendo en cuenta tus metas.",
    },
    {
        id: "4",
        title: "iA producción!",
        description: "Sacamos tu plataforma ala vida rea, lista para hacer conversiones y conectarnos con clientes .",
    },
]

const TimelineApp = ({classVar}) => {
    return (
        <div className="timeline-style-two bg-color-blackest">
            <div className="row row--0">
                {/* Start Single Progress */}
                {TimelineData.map((data , index) =>(
                    <div className={`col-lg-3 col-md-3 rn-timeline-single ${classVar}`} key={index}>
                        <div className="rn-timeline">
                           
                                <h6 className="title">{data.title}</h6>
                     
                            <div className="progress-line">
                                <div className="line-inner"></div>
                            </div>
                            <div className="progress-dot">
                                <div className="dot-level">
                                    <div className="dot-inner"></div>
                                </div>
                            </div>
                           
                                <p className="description">{data.description}</p>
                           
                        </div>
                    </div>
                ))}
                {/* End Single Progress */}
            </div>
        </div>
    )
}
export default TimelineApp;
