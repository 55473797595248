import React, { useState, useEffect } from "react";
import BlogClassicData from '../../../data/blog/BlogList.json';
import axios from 'axios';

import BlogList from './BlogList';
import { FaSpinner } from "react-icons/fa";
import filters from "./Filters";


const BlogProp = ({ column , StyleVarProp}) => {
    const [getAllItems, setGetAllItems] = useState();
    const [dataVisibleCount, setDataVisibleCount] = useState(6);
    const [dataIncrement] = useState(3) ;
    const [noMorePost, setNoMorePost] = useState(false);
    const [activeFilter, setActiveFilter] = useState("");
    const [visibleItems, setVisibleItems] = useState([]);

    const url = `https://kingart.com.co/world/wp-json/wp/v2/posts`;

     useEffect(() => {
        const getForecast = async () => {   
               
            try {
                const { data } = await axios.get(url);                
                setGetAllItems(data)
                } catch (error) {
                    console.log(error)            
            }
        }
        getForecast();

        }, [getAllItems])   


   
   
    
    return (
        <>
            <div className="col-lg-12">
                <div className="row row--15">
                    {getAllItems.map((item) => (
                        <div key={item.id} className={column}>
                        </div>
                    ))}
                </div>
            </div>
            <div className="col-lg-12 text-center">
                <div className="rwt-load-more text-center mt--60">
                    <button
                        className="btn btn-default btn-icon"
                        onClick=""
                        disabled={noMorePost ? "disabled" : null}
                    >
                        {noMorePost ? (
                        "No Post Here"
                        ) : (
                        <span>
                            View More Post 
                            <span className="icon">
                                <FaSpinner />
                            </span>
                        </span>
                        )}
                    </button>
                </div>
            </div>
        </>
    )
}

export default BlogProp;