import  React , { useState, useRef } from 'react';
import {AnimatePresence, motion , useMotionValue, useTransform } from "framer-motion/dist/framer-motion"; 

import HyperModal from 'react-hyper-modal';
import ModalServices from 'react-hyper-modal';

import SEO from "../../common/SEO";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import SystemSolar from "./SystemSolar";
import ModalApp from "./ModalApp";
import ModalDesign from "./ModalDesign";
import ModalMarketing from "./ModalMarketing";
import ModalWeb from "./ModalWeb";
import Modal from "./Modal";
import LandingApp from "../../pages/landing/LandingApp";
import LandingDesign from "../../pages/landing/LandingDesign";
import LandingWeb from "../../pages/landing/LandingWeb";
import LandingMarketing from "../../pages/landing/LandingMarketing";




function getRelativeCoordinates(event, referenceElement) {
  const position = {
    x: event.pageX,
    y: event.pageY
   };

  const offset = {
    left: referenceElement.offsetLeft,
    top: referenceElement.offsetTop,
    width: referenceElement.clientWidth,
    height: referenceElement.clientHeight
  };

  let reference = referenceElement.offsetParent;

  while (reference) {
    offset.left += reference.offsetLeft;
    offset.top += reference.offsetTop;
    reference = reference.offsetParent;
  }

  return {
    x: position.x - offset.left,
    y: position.y - offset.top,
    width: offset.width,
    height: offset.height,
    centerX: (position.x - offset.left - offset.width / 2) / (offset.width / 2),
    centerY: (position.y - offset.top - offset.height / 2) / (offset.height / 2)
  };
}

const Services = () => {
const [show, setShow] = useState(false);
const [text, setText] = useState("");
const x = useMotionValue(0);
const y = useMotionValue(0);


const [mousePosition, setMousePosition] = useState({});
const boxRef = useRef();

const rotateX = mousePosition.x;
const rotateY = mousePosition.y;
const rotateXd = mousePosition.width;
const rotateXdd =  useTransform(x, [0, rotateXd], [5, -5]);
const rotateYdd =  useTransform(y, [0, rotateXd], [-5, 5]);
const rotateXddl =  useTransform(x, [0, rotateXd], [-5, 5]);
const rotateYddl =  useTransform(y, [0, rotateXd], [-5, 5]);
const rotateXddll =  useTransform(x, [0, rotateXd], [7, -7]);
const rotateYddll =  useTransform(y, [0, rotateXd], [-7, 7]);
const rotateXdds =  rotateXd / 2 ;
const handleMouseMove = e => {
    setMousePosition(getRelativeCoordinates(e, boxRef.current));
    x.set(rotateX);
    y.set(rotateY);
};
const texts = {text};
  
    return (
        <section className="section-service-content" name="services" onMouseMove={e => handleMouseMove(e)}   ref={boxRef}>
            
             <HyperModal 
                isFullscreen
                isOpen={show}
                text={text}
                afterClose={() => { setShow(!show);}}
                >
                 {text === "DISEÑO UI UX" && (
                  <LandingWeb />
                  )}
                 {text === "FRONT END" && (
                  <LandingApp />
                  )}
                 {text === "BRANDING" && (
                  <LandingDesign />
                  )}
                 {text === "SOCIAL MEDIA" && (
                  <LandingMarketing />
                  )}
                </HyperModal>

            <SystemSolar  negx={rotateXddl} negy={rotateYddl} posx={rotateXdd} posy={rotateYdd} possx={rotateXddll} possy={rotateYddll}/>
             <motion.article className="services"  id="02" style={{
                    x: rotateXdd,
                     y: rotateYdd,
                }}
                >
                <ModalApp show={show} setShow={setShow} setText={setText} text={text} value="DISEÑO UI UX"/>
                <ModalApp show={show} setShow={setShow} setText={setText} text={text} value="SOCIAL MEDIA" />
                <ModalApp show={show} setShow={setShow} setText={setText} text={text} value="FRONT END" />
                <ModalApp show={show} setShow={setShow} setText={setText} text={text} value="BRANDING" />

                
            </motion.article>
        </section>
    )
};


export default Services;