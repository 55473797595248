import React , {useState}  from 'react';
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import HeaderTwo from '../common/header/HeaderTwo';
import HeaderTopNews from '../common/header/HeaderTopNews';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';

import LandingMarketing from './landing/LandingMarketing';

import ServiceFive from '../elements/service/ServiceFive';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import TeamTwo from '../elements/team/TeamTwo';
import TestimonialThree from "../elements/testimonial/TestimonialThree";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import Mission from "../elements/about/Mission";
import BlogListhome from "../components/blog/itemProp/BlogListhome";




const MarketingKing = () => {
    const [ofcanvasShow, setOffcanvasShow] = useState(false);
    return (
        <>
            <SEO title="Business Consulting 02" />
            <main className="page-wrapper pages">
            <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" setOffcanvasShow={setOffcanvasShow } ofcanvasShow={ofcanvasShow} />
                {/* Start Slider Area  */}
             
                <LandingMarketing />  
                <Separator />   
                <div className="blog-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Algunos trabajos realizados por King Art"
                                    title = "Últimos Del Portafolio."
                                    description = "<strong>Logo</strong>, <strong>Branding</strong>,<strong>Paginas Web</strong>, <strong>Tiendas Online</strong>, <br /><strong>Desarrollo Aplicaciones</strong> ,<strong>Diseño </strong>- <strong>Diseño Web</strong>."
                                />
                            </div>
                        </div> 
                        <div className="row row--15">      
                            <BlogListhome StyleVar="box-card-style-default"  />      
                        </div>
                    </div>
                </div>
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}
export default MarketingKing;
