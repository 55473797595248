import React from 'react';
import Typed from 'react-typed';

const MarketThree = () => {
    return (
        <div className="rwt-about-area rn-section-gap back-white">
            <div className="container">
                <div className="row row--30 align-items-center">

                    <div className="col-lg-5 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title color-black">Aparece en Google <br /> {" "}
                                    <span className="theme-gradient">
                                        SEO, posicionamiento orgánico
                                    </span>
                                </h2>
                                <p><strong> Posicionamiento Seo</strong>, Optimizamos tu Contenido y el codigo de tu Web para los buscadores, generamos estructuras legibles para Google y contenido de valor para que tus clientes te encuentren,Lluvia de ideas, Planificación, objetivos y diseños de vanguardia que son dirigidos a tu publico, asi como Estrategias para cumplir tus metas.</p>
                              
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-7">
                        <div className="thumbnail">
                            <img className="w-100" src="social03.webp" alt="About Images" />
                        </div>
                    </div>

                    
                </div>
            </div>   
        </div>
    )
}

export default MarketThree;   
