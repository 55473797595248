import React from 'react';
import Typed from 'react-typed';

const MarketOne = () => {
    return (
        <div className="rwt-about-area back-white vh100 mxw">
            <div className="container ">
                <div className="row row--30 align-items-center">

                    <div className="col-lg-5 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title color-black">Social Media <br /> {" "}
                                    <span className="theme-gradient">
                                        !Crea una máquina y acuéstate a dormir!
                                    </span>
                                </h2>
                                <p> El nuevo mundo... Las <strong>herramientas digitales</strong> con bases en fundamentos analogicos se convirtio en la forma de<strong> comunicación comercial</strong> de la actualidad, generando herramientas al alcance de cualquier consumidor,<strong> campañas de Marca </strong>(<strong>Facebook</strong>,<strong> Pinterest</strong>, <strong>Google</strong>, <strong>Bing</strong>, <strong>Linkedin</strong>), <strong>Campañas de Motores de Búsqueda</strong> por palabras claves (Google, Bing , etc)
        <br />El<strong> marketing digital</strong> (o <strong>marketing online</strong>) engloba todas aquellas acciones y <strong>estrategias publicitarias </strong>o comerciales que se ejecutan en los medios y <strong>canales de internet</strong>.</p>   
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-7 vh100">
                        <div className="thumbnail">
                            <img className="w-100" src="social011.png" alt="About Images" />
                        </div>
                    </div>

                    
                </div>
            </div>   
        </div>
    )
}

export default MarketOne;   
