import React from 'react';
import { Link, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li className="has-droupdown"><a href="/">Home</a>
            </li>
            
            <li><Link to="/about-us">Nosotros</Link></li>

            <li className="has-droupdown"><Link to="services">Servicios</Link>
                 <ul className="submenu">
                     <li><a href="/plataformas">App - Plataformas</a></li>
                     <li><a href="/paginas-web">Proyectos Web</a></li>
                     <li><a href="/social-media">Social Media</a></li>
                     <li><a href="/diseno-grafico">Imagen Corporativa</a></li>
                  </ul>                         
            </li>

            <li className="has-droupdown"><Link to="portfolio">Portafolio</Link>
                <ul className="submenu">
                    <li><a href="https://kingart.com.co/world/category/programacion/">Apicaciones</a></li>
                    <li><a href="https://kingart.com.co/world/category/diseno-web/">Diseño Web</a></li>
                    <li><a href="https://kingart.com.co/world/category/diseno-ui-ux/">Diseño UI / UX</a></li>
                    <li><a href="https://kingart.com.co/world/category/marketing-digital/">Marketing Digital</a></li>
                </ul>
            </li>

            <li className="with-megamenu"><a href="#">Productos</a>
                <div className="rn-megamenu">
                    <div className="wrapper">
                        <div className="row row--0">
                            <div className="col-lg-12 col-sm-12 single-mega-item">
                                 <ul className="mega-menu-item">
                                    <li><a href="https://kingart.com.co/world/categoria-producto/proyectos-web/"><strong>Proyectos Web</strong></a></li>
                                    <li><a href="https://kingart.com.co/world/producto/pagina-web-bogota/">Pagina Web</a></li>
                                    <li><a href="https://kingart.com.co/world/producto/proyectos-web-plataformas-web/">Plataformas Web</a></li>
                                    <li><a href="https://kingart.com.co/world/producto/proyectos-web-sistemas-web/">Diseño Web</a></li>
                                    <li><a href="https://kingart.com.co/world/producto/producto/proyectos-web-tiendas-online/">Tiendas Online</a></li>

                                </ul>
                            </div>
                            <div className="col-lg-12 col-sm-12 single-mega-item">
                                <ul className="mega-menu-item">
                                    <li><a href="https://kingart.com.co/world/categoria-producto/app-plataformas/"><strong>App / Plataformas</strong></a></li>
                                    <li><a href="https://kingart.com.co/world/producto/plataformas-apps-ecommerce/"> Ecommerce</a></li>
                                    <li><a href="https://kingart.com.co/world/producto/plataformas-apps-aplicaciones-moviles/">Aplicaciones Moviles</a></li>
                                    <li><a href="https://kingart.com.co/world/producto/plataformas-apps-plataformas-a-medida/">Plataformas a medida</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-12 col-sm-12 single-mega-item">
                                <ul className="mega-menu-item">
                                    <li><a href="https://kingart.com.co/world/categoria-producto/imagen-corporativa/"><strong>Imagen Corporativa</strong></a></li>
                                    <li><a href="https://kingart.com.co/world/producto/diseno-branding-plan-basico/">Logo para empresa</a></li>
                                    <li><a href="https://kingart.com.co/world/producto/diseno-branding-plan-estandar-logo-y-usos/"> Logo y usos</a></li>
                                    <li><a href="https://kingart.com.co/world/producto/diseno-branding-plan-premium-manual-de-marca/">Manual de Marca</a></li>
                                   
                                </ul>
                            </div>
                            <div className="col-lg-12 col-sm-12 single-mega-item">
                                <ul className="mega-menu-item">
                                    <li><a href="https://kingart.com.co/world/categoria-producto/social-media/"><strong>Social Media</strong> </a></li>
                                    <li><a href="https://kingart.com.co/world/producto/social-media-campana-sem/">Campaña SEM<span className="rn-badge-card">Promo</span></a></li>
                                    <li><a href="https://kingart.com.co/world/producto/social-media-campana-seo/">Campaña SEO <span className="rn-badge-card">Hot</span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </li>



          {/* <li className="has-droupdown"><a href="/contact">Contacto</a></li> */}
        </ul>
    )
}
export default Nav;
