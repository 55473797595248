import React from 'react';
import Typed from 'react-typed';

const AppFowr = () => {
    return (
        <div className="rwt-about-area rn-section-gap back-white">
            <div className="container">
                <div className="row row--30 align-items-center">
                <div className="col-lg-6">
                        <div className="thumbnail">
                            <img className="w-100" src="platform-5.jpg" alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-6 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title color-black">Destaca en el mercado <br /> {" "}
                                    <span className="theme-gradient">
                                        Sueño - Realidad
                                    </span>
                                </h2>
                                 <p>Las <strong> herramientas digitales</strong> llegaron para quedarse, ya que tienen mayor difusión a su público más específico, con módulos automáticos que reducirán procesos, conversiones reales con menos esfuerzo y lo mejor es que destaca sobre la competencia análoga <strong> teniendo aplicaciones </strong>potentes y mayor presencia en sus clientes.           </p>
                                  <div className="read-more-btn mt--40">
                                     <a className="btn-default" href="https://kingart.com.co/world/tienda/" target="_blank"><span>¡Contacto!</span></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    

                    
                </div>
            </div>   
        </div>
    )
}

export default AppFowr;   
