import  React , { useState, useRef , useEffect  } from 'react';

const WordGod = ({ textt , setTextt , textw }) => {
const [valuei, setValuei] = useState("");
const texttt = textt;
        window.addEventListener('load', function(){


            const canvas = document.getElementById('canvas1');
            const ctx = canvas.getContext('2d', {
                willReadFrequently: true
            });
            canvas.width = 400;
            canvas.height = window.innerHeight;

            class Particle {
                constructor(effect, x, y, color){
                    this.effect = effect;
                    this.x = Math.random() * this.effect.canvasWidth;
                    this.y = this.effect.canvasHeight;
                    this.color = color;
                    this.originX = x;
                    this.originY = y;
                    this.size = this.effect.gap;
                    this.dx = 0;
                    this.dy = 0;
                    this.vx = 0;
                    this.vy = 0;
                    this.force = 0;
                    this.angle = 0;
                    this.distance = 0;
                    this.friction = Math.random() * 0.6 + 0.25;
                    this.ease = Math.random() * 0.1 + 0.075;
                }
                draw(){
                    this.effect.context.fillStyle = this.color;
                    this.effect.context.fillRect(this.x, this.y, this.size, this.size);
                }
                update(){
                    this.dx = this.effect.mouse.x - this.x;
                    this.dy = this.effect.mouse.y - this.y;
                    this.distance = this.dx * this.dx + this.dy * this.dy;
                    this.force = -this.effect.mouse.radius / this.distance;

                    if (this.distance < this.effect.mouse.radius){
                        this.angle = Math.atan2(this.dy, this.dx);
                        this.vx += this.force * Math.cos(this.angle);
                        this.vy += this.force * Math.sin(this.angle);
                    }

                    this.x += (this.vx *= this.friction) + (this.originX - this.x) * this.ease;
                    this.y += (this.vy *= this.friction) + (this.originY - this.y) * this.ease;
                }
            }

            class Effect {
                constructor(context, canvasWidth, canvasHeight){
                    this.context = context;
                    this.canvasWidth = canvasWidth;
                    this.canvasHeight = canvasHeight;
                    this.textX = this.canvasWidth / 12;
                    this.textY = this.canvasHeight ;
                    this.fontSize = 80;
                    this.lineHeight = this.fontSize * .8;
                    this.maxTextWidth = this.canvasWidth * 0.8;
                    this.textInput = document.getElementById('butunpru');
                    this.verticalOffset = 0;
                     this.textInput.addEventListener('input', (e) => {
                        if (e.key !==' ') {
                            this.context.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
                            this.wrapText(e.target.value,e.target.name);
                        }
                    });
                    this.context.font = this.fontSize + 'px suranna regular';
                              
                    // particle text
                    this.particles = [];
                    this.gap = 1;
                    this.mouse = {
                        radius: 4000,
                        x: 0,
                        y: 0
                    }
                    window.addEventListener('mousemove', (e) => {
                        this.mouse.x = e.x;
                        this.mouse.y = e.y;
                    });           
                }

                wrapText(text , textn){
                 
                    const gradient = this.context.createLinearGradient(0, 0, this.canvasWidth, this.canvasHeight);
                    
                    gradient.addColorStop(0.3, 'gray');
                    gradient.addColorStop(0.7, 'white');
                    this.context.fillStyle = gradient;
                    this.context.textBaseline = '';
                    this.context.lineWidth = 1.2;
                    this.context.strokeStyle = 'black';
                    this.context.font = this.fontSize + 25 + 'px suranna regular';
                    let lineCounter = 0;
                    // break multiline text
                    let linesArray = [];
                    let textHeight = this.lineHeight * lineCounter;
                    this.textY = this.canvasHeight / 2 - textHeight / 2 + this.verticalOffset;

                    this.context.fillText(text, this.textX, this.textY + (3.6 * this.lineHeight));
                    this.context.strokeText(text, this.textX, this.textY + (3.6 * this.lineHeight));
                    
                    this.context.font = this.fontSize + 'px suranna regular';
                    this.context.fillText(textn, this.textX, this.textY + (4.6 * this.lineHeight));
                    this.context.strokeText(textn, this.textX, this.textY + (4.6 * this.lineHeight));
                    this.convertToParticles();
                }

               convertToParticles(){
                    this.particles = [];
                    const pixels = this.context.getImageData(0, 0, this.canvasWidth, this.canvasHeight).data;
                    this.context.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
                    for (let y = 0; y < this.canvasHeight; y += this.gap){
                        for (let x = 0; x < this.canvasWidth; x+= this.gap){
                            const index = (y * this.canvasWidth + x) * 4;
                            const alpha = pixels[index + 3];
                            if (alpha > 0){
                                const red = pixels[index];
                                const green = pixels[index + 1];
                                const blue = pixels[index + 2];
                                const color = 'rgb(' + red + ',' + green + ',' + blue + ')';
                                this.particles.push(new Particle(this, x, y, color));
                            }
                        }
                    }
                }

                render(){
                    this.particles.forEach(particle => {
                        particle.update();
                        particle.draw();
                    });
                }

                resize(width, height){
                    this.canvasWidth = width;
                    this.canvasHeight = height;
                    this.textX = this.canvasWidth / 2;
                    this.textY = this.canvasHeight / 2;
                    this.maxTextWidth = this.canvasWidth * 0.8;
                }
            }

            const effect = new Effect(ctx, canvas.width, canvas.height);
          

            function animate(){
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                effect.render();
                requestAnimationFrame(animate);
            }
           
            animate();

                const butun = document.getElementById('butunpru');


             butun.addEventListener('click', () => {
               canvas.width = 400;
               canvas.height = window.innerHeight;
               ctx.clearRect(0, 0, canvas.width, canvas.height);
               effect.wrapText(effect.textInput.value,effect.textInput.name);
            });

              window.addEventListener('resize', function(){
                const textInputT = document.getElementById('butunpru');
                canvas.width = 400;
                canvas.height = window.innerHeight;
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                effect.wrapText(effect.textInput.value,effect.textInput.name );

            });

                         

        });

        useEffect(() => {
            const butun = document.getElementById('butunpru');
            butun.click();                  
        }, [textt]);

         const handleChange = (event) => {
            setTextt(event.target.value);
          };
        
    return (
        <section>
              <button className={textw} id="butunpru" value={textt} name={textw} >Click , {textt}</button>

             <canvas id="canvas1" />

        </section>
    )
};


export default WordGod;