import React from 'react';
import { FiCheck } from "react-icons/fi";


const PricingApp = () => {
    return (
        <div className="row">
            <div className="col-lg-10 offset-lg-1">
                <div className="row row--0">
                    {/* Start PRicing Table Area  */}
                    <div className="col-lg-4 col-md-4 col-12">
                        <div className="rn-pricing style-2">
                            <div className="pricing-table-inner">
                                <div className="pricing-header">
                                    <h4 className="title">Ecommerce</h4>
                                    <div className="pricing">
                                        <div className="price-wrapper">
                                            <span className="currency">$</span>
                                            <span className="price">4 M</span>
                                        </div>
                                        <span className="subtitle">COP Por Proyecto</span>
                                    </div>
                                </div>
                                <div className="pricing-body">
                                    <ul className="list-style--1">
                                        <li><FiCheck /> Tiendas Web </li>
                                        <li><FiCheck /> Manejo de stock </li>
                                        <li><FiCheck /> Área de clientes </li>
                                        <li><FiCheck /> Chat Web </li>
                                        <li><FiCheck /> Carrito de Compras</li>
                                        <li><FiCheck /> Multiples medios de pago</li>   
                                        
                                    </ul>
                                </div>
                                <div className="pricing-footer">
                                     <a className="btn-default" href="https://kingart.com.co/world/producto/plataformas-apps-ecommerce/" target="_blank"><span>¡Contacto!</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End PRicing Table Area  */}

                    {/* Start PRicing Table Area  */}
                    <div className="col-lg-4 col-md-4 col-12">
                        <div className="rn-pricing style-2 active">
                            <div className="pricing-table-inner">
                                <div className="pricing-header">
                                    <h4 className="title">Plataformas desde</h4>
                                    <div className="pricing">
                                        <div className="price-wrapper">
                                            <span className="currency">$</span>
                                            <span className="price">7M</span>
                                        </div>
                                        <span className="subtitle">COP por plataforma</span>
                                    </div>
                                </div>
                                <div className="pricing-body">
                                    <ul className="list-style--1">
                                        <li><FiCheck /> Plataformas ala medida </li>
                                        <li><FiCheck /> ERP Administrativos </li>
                                        <li><FiCheck /> Desarrollo Modular</li>
                                        <li><FiCheck /> 100% Escalables </li>
                                        <li><FiCheck /> Sistemas de reservas </li>
                                        <li><FiCheck /> Plataformas Administrables</li>
                                        <li><FiCheck /> UI/UX Designs</li>
                                    </ul>
                                </div>
                                <div className="pricing-footer">
                                     <a className="btn-default" href="https://kingart.com.co/world/producto/plataformas-apps-plataformas-a-medida/" target="_blank"><span>¡Contacto!</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End PRicing Table Area  */}
 {/* Start PRicing Table Area  */}
                    <div className="col-lg-4 col-md-4 col-12">
                        <div className="rn-pricing style-2">
                            <div className="pricing-table-inner">
                                <div className="pricing-header">
                                    <h4 className="title">Aplicaciones (Apps) desde</h4>
                                    <div className="pricing">
                                        <div className="price-wrapper">
                                            <span className="currency">$</span>
                                            <span className="price">4 M</span>
                                        </div>
                                        <span className="subtitle">COP Por Proyecto</span>
                                    </div>
                                </div>
                                <div className="pricing-body">
                                    <ul className="list-style--1">
                                        <li><FiCheck /> Apps nativas-híbridas</li>
                                        <li><FiCheck /> Apps Administrables</li>
                                        <li><FiCheck /> Webservice Api</li>
                                        <li><FiCheck /> Mejores tecnologías</li>
                                        <li><FiCheck /> UI/UX Designs</li>
                                        <li><FiCheck /> Play store, App Store</li>
                                    </ul>
                                </div>
                                <div className="pricing-footer">
                                     <a className="btn-default" href="https://kingart.com.co/world/producto/plataformas-apps-aplicaciones-moviles/" target="_blank"><span>¡Contacto!</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End PRicing Table Area  */}                   
                </div>
            </div>
        </div>
    )
}
export default PricingApp;
