import React from 'react';
import Typed from 'react-typed';

const MarketTwo = () => {
    return (
        <div className="rwt-about-area rn-section-gap back-white">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-6">
                        <div className="thumbnail">
                            <img className="w-100" src="social02.png" alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-6 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title color-black">Marketing digital <br /> {" "}
                                    <span className="theme-gradient">
                                        SEM , posicionamiento pago
                                    </span>
                                </h2>
                                <p><strong>El SEM</strong> o <strong>Search Engine Marketing</strong> es una técnica de<strong> marketing online </strong>en la que buscamos<strong> dirigir tráfico </strong>de pago a<strong> nuestro Proyecto web</strong>  Sea <strong>pagina web</strong>, <strong>landing page</strong>, <strong>Apps</strong> a través de la <strong>página de resultados</strong> de un <strong>motor de búsqueda como Google </strong>o campañas visuales en<strong> Facebook</strong> o <strong>Instagram</strong> .
                                    Contamos Con <strong>Múltiples herramientas </strong>para crear <strong>campañas dinámicas</strong>, autosustentables que entreguen <strong>nuestro mensaje</strong> al comprador con <strong>más posibilidades de conversión.</strong>
                                </p>
                                <ul className="list-content" >
                                    <li>Analytics</li>
                                    <li>Adwords</li>
                                    <li>Google search</li>
                                    <li>MailChimp</li>
                                    <li>Zapier</li>
                                    <li>Salesforce</li>
                                    <li>Inbound Marketing</li>
                                </ul>
                                <div className="read-more-btn mt--40">
                                    <a className="btn-default" href="https://kingart.com.co/world/tienda/" target="_blank"><span>¡Contactame!</span></a>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>   
        </div>
    )
}

export default MarketTwo;   
