import React , { useState , useRef, useEffect }  from 'react';
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import ReactVivus from 'react-vivus';
import {AnimatePresence, motion, useScroll} from "framer-motion/dist/framer-motion";

import { FiArrowRight } from "react-icons/fi";
import HeaderTwo from '../common/header/HeaderTwo';
import HeaderTopNews from '../common/header/HeaderTopNews';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';

import AboutHome from '../elements/about/AboutHome';
import Start from '../elements/start/Start';
import Services from '../elements/services/Services';
import Portafolio from '../elements/portafolio/Portafolio';
import ClockSvg from '../elements/clock/Clock';
import WordGod from '../elements/word/WordGod';
import Nerv from '../elements/nerv/Nerv';

import ServiceFive from '../elements/service/ServiceFive';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import TeamTwo from '../elements/team/TeamTwo';
import TestimonialThree from "../elements/testimonial/TestimonialThree";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import Mission from "../elements/about/Mission";
import BlogListhome from "../components/blog/itemProp/BlogListhome";

const HomeKing = () => {
    const [textt, setTextt] = useState("");
    const [textw, setTextw] = useState("");
    const [nup, setNup] = useState("");
    const [ndown, setNdown] = useState("");
    const [visiblei, setVisiblei] = useState();
    const [ofcanvasShow, setOffcanvasShow] = useState(false);


const handleChanges = () => {
    setTextt("");
    setTextw("");
    setVisiblei(false);
};

const handleChangeo = () => { 
    setTextt("02");
    setTextw("Habilidades");
    setVisiblei(true);
    
};
  
const handleChanget = () => {
    setTextt("03");
    setTextw("Portafolio");
    setVisiblei(true);    

};

const handleChangetr = () => {    
    setTextt("04")
    setTextw("Ultimos");
    setVisiblei(true);
         
};

const handleChangefa = () => {
    setTextt("*/");
    setTextw("");
    setVisiblei(true);
};

useEffect(() => {
     if( textt == "") {
         setNup("");
         setNdown("services");
     }
     if( textt == "02") {
         setNup("null");
         setNdown("portfolio");
     }
    if( textt == "03") {
         setNup("services");
        setNdown("lasted");
        
    }
     if( textt == "04") {
        setNup("portfolio");
        setNdown("footers");
    }
    if( textt == "*/") {
        setNup("lasted");
       setNdown("");
    }
                       
}, [textt]);   

return (
    <>
        <SEO title="King Art - Design Ui/Ux - SEO WPO - Front End"
            description="Agencia desarrollo Web Bogotá, Paginas Web Bogotá, Diseño Web, App Plataformas Bogotá"
            keywords="Paginas Web Bogotá, Paginas Web, Ecommerce Bogotá, Tiendas online, Diseño Bogota, Diseño Web" 
        /> 

        <main className={`page-wrapper ${ofcanvasShow? "activemm": ""}`}>
            <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" setOffcanvasShow={setOffcanvasShow } ofcanvasShow={ofcanvasShow} />

            {visiblei ? (
            <Nerv textt={textt} textw={textw} setTextt={setTextt} setNup={setNup} nup={nup} setNdown={setNdown} ndown={ndown}  />
            ) : (
            <div className="red2" />
            )}

            <WordGod textt={textt} textw={textw} setTextt={setTextt} />

            {/* Start Start Area  */}
            <motion.div className="back-white vh100 " name="null" onViewportEnter={handleChanges}>
                <Start />      
            </motion.div>
            {/* End Start Area   */}

            <motion.div className="rn-service-area back-white  vh70" >
                <div className="container start">
                    <div className="row" id="01">
                        <div className="col-lg-12 color-black">
                            <SectionTitle
                                textAlign = "text-center"
                                radiusRounded = ""
                                subtitle = "King Art "
                                title = ""
                                description = "Soy King Art , Design Ui/Ux | SEO - WPO | Frontend developer, apasionado por la web, me gusta combinar el propósito de los objetivos con el diseño de interfaces que los cumplan, crear productos amigables para los usuarios, Esta es una muestra de mi recorrido.
                                "
                            />
                        </div>
                    </div>                    
                </div>
            </motion.div>

            <motion.div className="separator-chnage1" >
            </motion.div>

            <motion.div className="rn-service-area relati back-white services-section" onViewportEnter={handleChangeo} >
                <SectionTitle
                    textAlign = "text-center"
                    className="title-res-m"
                    radiusRounded = ""
                    subtitle = ""
                    title = "Herramientas"
                    description = ""
                />
                <Services />
            </motion.div>

            <motion.div className="triangle-down">      
            </motion.div>

                  {/* Start Service Area  */}
            <motion.div className=" rn-section-gap portfolio-section"  name="portfolio" onViewportEnter={handleChanget} viewport={{ once: false, }}>
                <SectionTitle
                    textAlign = "text-center"
                    className="title-res-m"
                    radiusRounded = ""
                    subtitle = ""
                    title = "Portafolio"
                    description = ""
                />
                <Portafolio />   
            </motion.div>
                {/* End Service Area  */}
            <motion.div className=" lasted-section" name="lasted" onViewportEnter={handleChangetr} viewport={{ once: false, }}>
                <SectionTitle
                    textAlign = "text-center"
                    className="title-res-m"
                    radiusRounded = ""
                    subtitle = ""
                    title = "Ultimos"
                    description = ""
                />
                <BlogListhome StyleVar="box-card-style-default"  />                              
            </motion.div>
            <motion.div onViewportEnter={handleChangefa} name="footers" className="footer-st">
                <FooterTwo />  
            </motion.div>
                
            <Copyright />
            </main>
    </>
    )
}
export default HomeKing;
