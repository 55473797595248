import React from 'react'
import HeaderTopNews from '../../common/header/HeaderTopNews';
import HeaderOne from '../../common/header/HeaderOne';
import FooterFour from '../../common/footer/FooterFour';
import BrandThree from '../../elements/brand/BrandThree';
import AboutFour from '../../elements/about/AboutFour';
import ServiceOne from "../../elements/service/ServiceOne";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../../elements/counterup/CounterUpFour";
import TeamFour from "../../elements/team/TeamFour";
import TimelineWeb from "../../elements/timeline/TimelineWeb";
import Separator from "../../elements/separator/Separator";
import AccordionOne from "../../elements/accordion/AccordionOne";

import WebOne from '../../elements/about/WebOne';
import WebTwo from '../../elements/about/WebTwo';
import WebThree from '../../elements/about/WebThree';
import PricingWeb from '../../elements/pricing/PricingWeb';
import ProgressbarDes from "../../elements/progressbar/ProgressbarDes";



const LandingWeb = () => {
    return (
        <>
            <main className="page-wrapper">

                {/* Start Slider Area  */}
                    <WebOne /> 
                {/* End Slider Area  */}

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Mis Habilidades En El Campo"
                                    title = "Soy Diseñador UI IX "
                                    description = "En Diseño Ui Ux cuento con experiencia de mas de 8 años, desde la formulación de objetivos y estrategias, pasando por Wireframes, Diseños de alta fidelidad, llegando ala maquetación de interfaces graficas."
                                    />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <TimelineWeb classVar="no-gradient"  />
                            </div>
                        </div>
    
                    </div>
                </div>
                {/* End Service Area  */}

                

                <WebTwo /> 
{/* Start Elements Area  */}
<div className="rwt-about-area rn-section-gap">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <SectionTitle
                                    textAlign = "text-left"
                                    radiusRounded = ""
                                    subtitle = "Skills y Software"
                                    title = "Herramientas Ui Ux"
                                    description = "El mundo digital es extenso, lo que me ha llevado a usar distintas herramientas para diseño y presentaciones."
                                />
                            </div>
                            <div className="col-lg-6">
                                <div className="rbt-progress-style-1 enable-gradient-fill no-radius">
                                    <ProgressbarDes />
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                {/* End Elements Area  */}

            {/* Start Service Area  
                <div className="service-area rn-section-gapTop ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="content">
                                    <h3 className="title">Preguntas frecuentes en un proyecto o desarrollo web</h3>
                                    <p> Como en todo existen mitos, si tienes dudas sobre cualquier tipo de desarrollo o sus autorias puedes escribitnos, o comunicarte con nosotros </p>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="col-lg-10 offset-lg-1">
                                    <AccordionOne customStyle="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               End Service Area  */}
    
                <WebThree /> 

               <div className="service-area rn-section-gapTop display-none">
                    <div className="container">
                        <PricingWeb />
                    </div>
                </div>

                <FooterFour />
            </main>
        </>
    )
}

export default LandingWeb;
