import React , { useRef } from 'react';
import {AnimatePresence, motion, useScroll} from "framer-motion/dist/framer-motion";



const SystemSolar = ({negx , negy , posx ,posy ,possx , possy  }) => {

return (
	<div className="systemsolar">
		<motion.svg  className="solar" data-name="Layer 1" width="100%" height="100%" style={{
			x: negx,
			y: negy,
		}}>
			 <motion.circle className="cls-2" cx="50%" cy="50%" r="10%" />
			 <motion.circle className="cls-4" cx="50%" cy="50%" r="15%"/>
			 <motion.circle className="cls-2" cx="50%" cy="50%" r="22%"/>
			 <motion.circle className="cls-4" cx="50%" cy="50%" r="28%"/>
			 <motion.circle className="cls-4" cx="50%" cy="50%" r="35%"/>
			 <motion.circle className="cls-4" cx="50%" cy="50%" r="40%"/>
			 <motion.circle className="cls-4" cx="50%" cy="50%" r="45%"/>
			 <motion.circle className="cls-4" cx="50%" cy="50%" r="50%"/>
		</motion.svg>

		<svg className="pecado-pilar pecado-pilar3" viewBox="0 0 81.19 959.51">
			<line className="pecado-pilar-3" x1="40.86" y1="1.25" x2="40.86" y2="958.51"/>
		</svg>
		<svg className="pecado-pilar pecado-pilar1 " viewBox="0 0 81.19 959.51">
			<path className="pecado-pilar-1" d="M38.14,234.88c0-1.5,1.22-2.72,2.72-2.72s2.72,1.22,2.72,2.72-1.22,2.72-2.72,2.72-2.72-1.22-2.72-2.72Z"/>
			<path className="pecado-pilar-2" d="M74.28,254.33l-33.69,19.45L6.91,254.33v-38.9l33.69-19.45,33.69,19.45v38.9ZM7,234.88c0,18.55,15.04,33.59,33.59,33.59s33.59-15.04,33.59-33.59-15.04-33.59-33.59-33.59S7,216.33,7,234.88Zm33.59-38.9c-21.48,0-38.9,17.41-38.9,38.9s17.41,38.9,38.9,38.9,38.9-17.41,38.9-38.9-17.41-38.9-38.9-38.9Zm0,0L6.91,254.33H74.28l-33.69-58.34Zm0,77.79v-77.79m33.69,19.45L6.91,254.33m67.37,0L6.91,215.43m14.32,19.45c0,10.69,8.67,19.36,19.36,19.36s19.36-8.67,19.36-19.36-8.67-19.36-19.36-19.36-19.36,8.67-19.36,19.36Zm-4.97,0c0,13.44,10.89,24.33,24.33,24.33s24.33-10.89,24.33-24.33-10.89-24.33-24.33-24.33-24.33,10.89-24.33,24.33Zm24.33-19.36l-16.84,9.64v19.4l16.84,9.68,16.77-9.68v-19.36l-16.77-9.69Z"/>
		</svg>
			<svg className="pecado-pilar pecado-pilar2 " viewBox="0 0 81.19 959.51">
			<path className="pecado-pilar-1" d="M38.14,787.91c0-1.5,1.22-2.72,2.72-2.72s2.72,1.22,2.72,2.72-1.22,2.72-2.72,2.72-2.72-1.22-2.72-2.72Z"/>
			<path className="pecado-pilar-2" d="M74.54,807.52l-33.69,19.45-33.69-19.45v-38.9l33.69-19.45,33.69,19.45v38.9Zm-67.28-19.45c0,18.55,15.04,33.59,33.59,33.59s33.59-15.04,33.59-33.59-15.04-33.59-33.59-33.59-33.59,15.04-33.59,33.59Zm33.59-38.9c-21.48,0-38.9,17.41-38.9,38.9s17.41,38.9,38.9,38.9,38.9-17.41,38.9-38.9-17.41-38.9-38.9-38.9Zm0,0l-33.69,58.34H74.54l-33.69-58.34Zm0,77.79v-77.79m33.69,19.45L7.17,807.52m67.37,0L7.17,768.63m14.32,19.45c0,10.69,8.67,19.36,19.36,19.36s19.36-8.67,19.36-19.36-8.67-19.36-19.36-19.36-19.36,8.67-19.36,19.36Zm-4.97,0c0,13.44,10.89,24.33,24.33,24.33s24.33-10.89,24.33-24.33-10.89-24.33-24.33-24.33-24.33,10.89-24.33,24.33Zm24.33-19.36l-16.84,9.64v19.4l16.84,9.68,16.77-9.68v-19.36l-16.77-9.69Z"/>
		</svg>

		<motion.svg  className="solar" data-name="Layer 1" width="100%" height="100%"xmlns="http://www.w3.org/2000/svg"  style={{
			x: posx ,
			y: posy,
		}}>
		  <motion.path className="cls-3 pl2" d="M11.17,39.54l10.59-18.33M.58,21.21H21.75m19.49,0C41.24,10.44,32.52,1.72,21.75,1.72S2.27,10.44,2.27,21.21s8.72,19.49,19.49,19.49,19.49-8.72,19.49-19.49Zm-8.9,18.33l10.58-18.33L32.34,2.87H11.17L.58,21.21l10.58,18.33h21.17ZM11.17,2.87l10.59,18.33m15.85,9.12L21.75,2.87,5.91,30.32h31.7ZM5.9,12.07l15.86,27.47,15.86-27.47H5.9Zm15.86,9.13V2.87M5.91,30.32l15.85-9.12m15.85,9.12l-15.85-9.12m9.13,0c0-5.04-4.09-9.13-9.13-9.13s-9.13,4.09-9.13,9.13,4.09,9.13,9.13,9.13,9.13-4.09,9.13-9.13Zm-2.37,0c0-3.73-3.03-6.76-6.76-6.76s-6.76,3.03-6.76,6.76,3.03,6.76,6.76,6.76,6.76-3.03,6.76-6.76ZM21.75,2.87L5.9,12.07V30.32s15.86,9.22,15.86,9.22l15.85-9.22V12.07S21.75,2.87,21.75,2.87Zm0,18.33l15.86-9.13m-15.86,27.47V21.21M5.9,12.07l15.86,9.13m0,0l10.58,18.33m-10.58-18.3L32.34,2.91m10.58,18.33H21.75"/>
		  <motion.path className="cls-3 pl4" d="M42.54,10.61l-17.51,30.32L7.53,10.61H42.54Zm0,30.32L25.04,10.61,7.53,40.93H42.54ZM1.64,25.77H48.43M25.04,2.38V49.16M7.53,10.61l26.26,15.16m8.75-15.16l-26.18,15.16m-8.84,15.16l26.26-15.16m8.75,15.16l-26.18-15.16m8.67,15.16c8.37,0,15.16-6.79,15.16-15.16s-6.79-15.16-15.16-15.16-15.16,6.79-15.16,15.16,6.79,15.16,15.16,15.16Zm0,8.23c12.92,0,23.39-10.47,23.39-23.39S37.96,2.38,25.04,2.38,1.64,12.85,1.64,25.77s10.47,23.39,23.39,23.39Z"/>
		  <motion.path className="cls-3 pl6" d="M71.55,56.88l-32.24,18.61L7.07,56.88V19.65L39.31,1.04l32.24,18.61V56.88ZM2.09,38.26c0,20.56,16.67,37.23,37.23,37.23s37.23-16.67,37.23-37.23S59.87,1.04,39.31,1.04,2.09,17.71,2.09,38.26Zm6.34-.27c0,17.06,13.83,30.88,30.88,30.88s30.88-13.83,30.88-30.88S56.37,7.11,39.31,7.11,8.43,20.94,8.43,37.99Zm-1.26-18.41l32.14,55.67L71.46,19.58H7.17Zm58.74,33.6L39.31,7.11,12.71,53.18h53.2Zm-26.6,22.07V37.93m32.14-18.34l-32.14,18.34M7.17,19.58l32.14,18.34m0,14.96c8.25,0,14.94-6.69,14.94-14.94s-6.69-14.94-14.94-14.94-14.94,6.69-14.94,14.94,6.69,14.94,14.94,14.94Z"/>
		  <motion.path className="cls-3 pl8" d="M36.02,8.07l-14.74,25.53L6.54,8.07h29.48Zm0,25.53L21.28,8.07,6.54,33.6h29.48ZM1.59,20.83H40.98M21.28,1.14V40.53M6.54,8.07l22.11,12.76m7.37-12.76L13.98,20.83m-7.44,12.76l22.11-12.76m7.37,12.76L13.98,20.83m7.3,12.76c7.05,0,12.76-5.71,12.76-12.76s-5.71-12.76-12.76-12.76-12.76,5.71-12.76,12.76,5.71,12.76,12.76,12.76Zm0,6.93c10.88,0,19.69-8.82,19.69-19.69S32.16,1.14,21.28,1.14,1.59,9.95,1.59,20.83s8.82,19.69,19.69,19.69Z"/>
		</motion.svg>

		<motion.svg  className="solar" data-name="Layer 1" width="100%" height="100%"xmlns="http://www.w3.org/2000/svg"  style={{
			x: negx,
			y: negy,
		}}>

		  <motion.path className="cls-3 pl1" d="M34.28,27.28l-15.04,8.68L4.21,27.28V9.91L19.24,1.23l15.04,8.68V27.28ZM1.88,18.59c0,9.59,7.77,17.36,17.36,17.36s17.36-7.77,17.36-17.36S28.83,1.23,19.24,1.23,1.88,9,1.88,18.59Zm2.96-.13c0,7.96,6.45,14.41,14.41,14.41s14.41-6.45,14.41-14.41-6.45-14.41-14.41-14.41S4.84,10.51,4.84,18.47Zm-.59-8.59l14.99,25.97,14.99-25.97H4.25Zm27.4,15.67L19.24,4.06,6.84,25.55H31.65Zm-12.41,10.29V18.44m14.99-8.56l-14.99,8.56M4.25,9.88l14.99,8.56m0,6.98c3.85,0,6.97-3.12,6.97-6.97s-3.12-6.97-6.97-6.97-6.97,3.12-6.97,6.97,3.12,6.97,6.97,6.97Z"/>
		  <motion.path className="cls-3 pl3" d="M53.11,43l-23.47,13.55L6.17,43V15.9L29.64,2.35l23.47,13.55v27.1Zm0,0L29.64,2.35,6.17,43H53.11Zm3.63-13.55c0-14.97-12.13-27.1-27.1-27.1S2.54,14.48,2.54,29.45s12.13,27.1,27.1,27.1,27.1-12.13,27.1-27.1ZM6.17,15.85L29.64,56.5,53.11,15.85H6.17Zm23.47,13.6V2.35M6.17,43l23.47-13.55m23.47,13.55l-23.47-13.55m8.05-19.05c0-4.45-3.61-8.05-8.05-8.05s-8.05,3.61-8.05,8.05,3.61,8.05,8.05,8.05,8.05-3.61,8.05-8.05Zm-4.03,42.07c0-2.22-1.8-4.03-4.03-4.03s-4.03,1.8-4.03,4.03,1.8,4.03,4.03,4.03,4.03-1.8,4.03-4.03Zm2.75-23.02c0-3.74-3.03-6.78-6.78-6.78s-6.78,3.03-6.78,6.78,3.03,6.78,6.78,6.78,6.78-3.03,6.78-6.78Zm-4.57,0c0-1.22-.99-2.2-2.2-2.2s-2.2,.99-2.2,2.2,.99,2.2,2.2,2.2,2.2-.99,2.2-2.2Z"/>
		  <motion.path className="cls-3 pl5" d="M16.84,56.19l14.62-25.33m-29.25,0H31.47m26.92,0c0-14.87-12.05-26.92-26.92-26.92S4.54,15.99,4.54,30.86s12.05,26.92,26.92,26.92,26.92-12.05,26.92-26.92Zm-12.3,25.33l14.62-25.33L46.09,5.53H16.84L2.22,30.86l14.62,25.33h29.25Zm-14.62-25.33L16.84,5.53M53.36,43.46L31.47,5.53,9.57,43.46H53.36ZM9.56,18.25L31.47,56.19,53.38,18.25H9.56Zm21.91,12.62V5.53m0,25.33l-21.9,12.59m43.79,0l-21.9-12.59m12.62,0c0-6.97-5.65-12.62-12.62-12.62s-12.62,5.65-12.62,12.62,5.65,12.62,12.62,12.62,12.62-5.65,12.62-12.62Zm-3.28,0c0-5.16-4.18-9.34-9.34-9.34s-9.34,4.18-9.34,9.34,4.18,9.34,9.34,9.34,9.34-4.18,9.34-9.34ZM31.47,5.53L9.56,18.25v25.21s21.91,12.74,21.91,12.74l21.9-12.74V18.25S31.47,5.53,31.47,5.53Zm0,25.33l21.91-12.62M31.47,56.19V30.86M9.56,18.25l21.91,12.62m14.62,25.33l-14.62-25.33M46.09,5.58l-14.62,25.33m29.25,0H31.47"/>
		  <motion.path className="cls-3 pl7" d="M29.95,57.42c-15.19,0-27.51-12.31-27.51-27.51S14.75,2.41,29.95,2.41s27.51,12.31,27.51,27.51-12.31,27.51-27.51,27.51Zm23.6-13.88L29.95,2.66,6.34,43.55H53.55ZM6.34,16.35L29.95,57.23,53.55,16.35H6.34Zm39.37,13.57h11.74m-19.62-13.66l5.87-10.16m-21.64,10.16l-5.87-10.16m-2.02,23.82H2.44m19.62,13.66l-5.87,10.16m21.64-10.16l5.87,10.16m4.6-13.22L29.95,8.73,11.59,40.52H48.3ZM11.59,19.37l18.35,31.79,18.35-31.79H11.59ZM29.95,2.66V29.92M6.34,43.55l23.6-13.63m23.6,13.63l-23.6-13.63"/>
		</motion.svg>
		<motion.svg  className="sun" data-name="Layer 1" viewBox="0 0 107.34 107.14" style={{
			x: possx ,
			y: possy,
		}}>
			<motion.path className="cls-1 " d="M33.68,102.43L5.03,73.78V33.25L33.68,4.6h40.52l28.65,28.65v40.52l-28.65,28.65H33.68ZM16.51,16.08L1,53.51l15.51,37.44,37.44,15.51,37.44-15.51,15.51-37.44-15.51-37.44L53.95,.57,16.51,16.08Zm58.75,58.7l16.12,16.18M16.51,16.08l16.15,16.15m-.03,42.54l-16.12,16.18M91.38,16.08l-16.12,16.12m8.79,21.31h22.84M1,53.51H23.84m30.1,30.1v22.84M53.95,.57V23.41m30.07,28.69l18.85-18.85M33.68,102.43l18.63-18.81m1.64,22.84l52.95-52.95M55.36,23.45L74.21,4.6M5.03,73.78l18.81-18.81M53.95,.57L1,53.51m54.58,30.1l18.63,18.81M5.03,33.25l18.85,18.85m60.14,2.84l18.85,18.83M52.31,23.46L33.68,4.6m20.26,101.86L1,53.51M53.95,.57l52.95,52.95M25.91,42.52L5.03,73.78M53.95,.57l-20.9,31.28m43.17,41.93h26.65m-97.83,0H31.68M74.85,31.85L53.95,.57m48.92,73.21l-20.78-31.1m-5.87-9.42h26.65m-97.83,0H31.68m43.58,41.52l-21.31,31.69M102.86,33.25l-20.94,31.4m-56.01-.15L5.03,33.25M53.95,106.46l-20.9-31.28m20.9-41.41l-19.74,19.74,19.74,19.74,19.74-19.74-19.74-19.74Zm0,18.36c-.77,0-1.39,.62-1.39,1.39s.62,1.39,1.39,1.39,1.39-.62,1.39-1.39-.62-1.39-1.39-1.39Zm-19.87,1.39h39.74m-19.87-19.74v39.62"/>
		   <motion.path className="cls-5 " d="M53.95,23.41c-16.63,0-30.1,13.48-30.1,30.1s13.48,30.1,30.1,30.1,30.1-13.48,30.1-30.1-13.48-30.1-30.1-30.1Zm0,49.98c-10.97,0-19.87-8.9-19.87-19.87s8.9-19.87,19.87-19.87,19.87,8.9,19.87,19.87-8.9,19.87-19.87,19.87Z"/>
		</motion.svg>	
	</div>
	

)};
export default SystemSolar;