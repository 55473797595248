import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import { FiPlay } from "react-icons/fi";

const PopupContent =  {
    image: "./platform-11.jpg",
    popupLink: [ "./Vivero-OnLine-Jardines-de-Zion-Soacha-Google-Chrome-11.mp4.mp4"],
}



function VideoOne() {
    const [toggler, setToggler] = useState(false);
    return (
       <>
                <div className="video-popup icon-center">
                    <div className="thumbnail">
                        <img className="radius-small" src={`${PopupContent.image}`} alt="Corporate Image" />
                    </div>
                    <div className="video-icon">
                        <button className="btn-default rounded-player" onClick={ () => setToggler(!toggler) }><span><FiPlay /></span></button>
                    </div>
                    <FsLightbox toggler={ toggler } sources={PopupContent.popupLink} />
                </div>
          
         </>
    )
}
export default VideoOne;