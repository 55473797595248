import React from 'react';
import SidebarTag from './sidebar/SidebarTag';
import SideCategories from './sidebar/SideCategories';
import Comment from './Comment';
import {slugify} from "../../utils";
import {Link} from "react-router-dom";
import { FiUser, FiCalendar } from "react-icons/fi";



const BlogDetailsContent = ({ data }) => {
    console.log(data.title.rendered );

     const createMarkup = (html) => {
        return { __html: html };
    }
    return (
       <>
            <div className="post-page-banner rn-section-gapTop">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="content text-center">
                                <div className="page-title">
                                    <h1 className="theme-gradient">{data.title.rendered}</h1>
                                </div>
                                <ul className="rn-meta-list">
                                    <li><FiCalendar />{data.date}</li>
                                </ul>
                                <div className="thumbnail alignwide mt--60">
                                    <img className=" radius" src={data.yoast_head_json.og_image[0].url} alt="Blog Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blog-details-content pt--60 rn-section-gapBottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="content">
                                <div
                                 dangerouslySetInnerHTML={createMarkup(
                                  data.content.rendered
                                 )}
                                 />
                                <div className="category-meta">
                                    <span className="text">Tags:</span>
                                    <SidebarTag />
                                </div>

                                <div className="rn-comment-form pt--60">
                                    <div className="comment-respond">
                                        <h4 className="title mb--40">Leave a Reply</h4>
                                        <Comment 
                                            url=""
                                            id={data.id}
                                            title={data.title.rendered}
                                        />
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

                              



        </>
    )
}
export default BlogDetailsContent;
