import React , {useState, useEffect}from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom'
import BlogDetailsContent from '../components/blog/BlogDetailsContent';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import { FiUser, FiCalendar } from "react-icons/fi";

const BlogDetails = () => {
    const [post, setPost] = useState(null);
    const { slug } = useParams();
    

    useEffect(() => {
        const getForecast = async () => {   
            const url = `https://kingart.com.co/world/wp-json/wp/v2/posts?slug=${slug}`;
               
            try {
                const { data } = await axios.get(url);                
                setPost(data[0])
                } catch (error) {
                    console.log(error)            
            }
        }
        getForecast();

        }, [slug])   

    return (
        <>
            <SEO title="Blog Details || KING ART" />
            <Layout>
                <div className="row">
                    <div className="col-sm-10">
                        <div className="rn-blog-details-area">
                            {
                                post && <BlogDetailsContent data={post}  />
                            }
                        </div>
                        
                    </div>
                    <div className="col-sm-2">
                        
                    </div>
                   
                </div>
            </Layout>
        </>
    )
}


export default BlogDetails;


             
