import React from 'react';
import TrackVisibility from "react-on-screen";
import Skill from "./Skill";

const progressData = [
    {
      id: 1,
      title: "Diseño Ui/Ux - Wireframes - Layout",
      percantage: 100,
      progressColor: "radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%)",
    },
    {
      id: 2,
      title: "Adobe Illustrator - Adobe Xd - Photoshop",
      percantage: 90,
      progressColor: "radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%)",
    },
    {
      id: 3,
      title: "Figma - Sketch - Elementor",
      percantage: 90,
      progressColor: "radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%)",
    },
    {
      id: 4,
      title: "Design Thinking - Lean ux - Scrum",
      percantage: 100,
      progressColor: "radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%)",
    },
    {
        id: 5,
        title: "Html - Css - React - Php - Vue",
        percantage: 100,
        progressColor: "radial-gradient(circle farthest-corner at 0% 0%, var(--color-pink) 25%, var(--color-secondary) 45%, var(--color-primary) 65%, var(--color-light-green) 85%)",
      },
      
];



const ProgressbarDes = () => {
    return (
        <>
            {progressData.map((progress) => (
            <TrackVisibility
                once
                key={progress.id}
                className="single-progress"
            >
                <Skill progress={progress} /> 
            </TrackVisibility>
            ))} 
        </>
    )
}

export default ProgressbarDes;
