import React from 'react';
import { FiCheck } from "react-icons/fi";


const PricingWeb = () => {
    return (
        <div className="row row--0">

            {/* Start PRicing Table Area  */}
            <div className="col-lg-3 col-md-6 col-12">
                <div className="rn-pricing style-4">
                    <div className="pricing-table-inner">
                        <div className="pricing-header">
                            <h4 className="title">Paginas Web desde</h4>
                            <div className="pricing">
                                <div className="price-wrapper">
                                    <span className="currency">$</span>
                                    <span className="price">1.2M</span>
                                </div>
                                <span className="subtitle">Por Proyecto</span>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul className="list-style--1">
                                <li><FiCheck /> Portafolio</li>
                                <li><FiCheck /> Paginas corporativas</li>
                                <li><FiCheck /> Sliders Promocionales</li>
                                <li><FiCheck /> Administrable</li>
                                <li><FiCheck /> Recolector de datos</li>
                                <li><FiCheck /> SEO</li>
                                <li><FiCheck /> Chat Online</li>
                            </ul>
                        </div>
                        <div className="pricing-footer">
                             <a className="btn-default" href="https://kingart.com.co/world/producto/pagina-web-bogota/" target="_blank"><span>¡Contacto!</span></a>
                        </div>
                        
                    </div>
                </div>
            </div>
            {/* End PRicing Table Area  */}

            {/* Start PRicing Table Area  */}
            <div className="col-lg-3 col-md-6 col-12">
                <div className="rn-pricing style-4">
                    <div className="pricing-table-inner">
                        <div className="pricing-header">
                            <h4 className="title">Diseño Web</h4>
                            <div className="pricing">
                                <div className="price-wrapper">
                                    <span className="currency">$</span>
                                    <span className="price">3M</span>
                                </div>
                                <span className="subtitle">Por Proyecto</span>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul className="list-style--1">
                                <li><FiCheck /> Sistemas de tickets</li>
                                <li><FiCheck /> Diseño de Blogs</li>
                                <li><FiCheck /> Paginas Web</li>
                                <li><FiCheck /> Piezas Marketplace </li>
                                <li><FiCheck /> Sliders Promocionales</li>
                                <li><FiCheck /> Contenido Administrable</li>
                                <li><FiCheck /> Recolector de datos</li>
                                <li><FiCheck /> SEO</li>
                            </ul>
                        </div>
                        <div className="pricing-footer">
                             <a className="btn-default" href="https://kingart.com.co/world/producto/proyectos-web-diseno-web/" target="_blank"><span>¡Contacto!</span></a>
                        </div>
                    </div>
                </div>
            </div>
            {/* End PRicing Table Area  */}

            {/* Start PRicing Table Area  */}
            <div className="col-lg-3 col-md-6 col-12">
                <div className="rn-pricing style-4 active">
                    <div className="pricing-table-inner">
                        <div className="pricing-header">
                            <h4 className="title">Tiendas Online desde</h4>
                            <div className="pricing">
                                <div className="price-wrapper">
                                    <span className="currency">$</span>
                                    <span className="price">3M</span>
                                </div>
                                <span className="subtitle">USD Per Month</span>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul className="list-style--1">
                                <li><FiCheck /> Capacidad +5000 productos</li>
                                <li><FiCheck /> Manejo de Stock</li>
                                <li><FiCheck /> Métricas Avanzadas</li>
                                <li><FiCheck /> Proyectos Modulares</li>
                                <li><FiCheck /> Promociones</li>
                                <li><FiCheck /> Sliders Promocionales</li>
                                <li><FiCheck /> Administrable</li>
                                <li><FiCheck /> Recolector de datos</li>
                                <li><FiCheck /> SEO</li>
                                <li><FiCheck /> Chat Online</li>
                            </ul>
                        </div>
                        <div className="pricing-footer">
                             <a className="btn-default" href="https://kingart.com.co/world/producto/proyectos-web-tiendas-online/" target="_blank"><span>¡Contacto!</span></a>
                        </div>
                    </div>
                </div>
            </div>
            {/* End PRicing Table Area  */}

            {/* Start PRicing Table Area  */}
            <div className="col-lg-3 col-md-6 col-12">
                <div className="rn-pricing style-4">
                    <div className="pricing-table-inner">
                        <div className="pricing-header">
                            <h4 className="title">Plataformas Web desde</h4>
                            <div className="pricing">
                                <div className="price-wrapper">
                                    <span className="currency">$</span>
                                    <span className="price">5M</span>
                                </div>
                                <span className="subtitle">Por Proyecto</span>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul className="list-style--1">
                                <li><FiCheck /> Desarrollos ala medida </li>
                                <li><FiCheck /> Desarrollos sobre Cms</li>
                                <li><FiCheck /> Wordpress</li>
                                <li><FiCheck /> Prestashop</li>
                                <li><FiCheck /> Shopify</li>
                                <li><FiCheck /> Desarrollo de plugins </li>
                                <li><FiCheck /> Conexiones webservice</li>
                                <li><FiCheck /> Sistemas Administrativos</li>
                            </ul>
                        </div>
                        <div className="pricing-footer">
                             <a className="btn-default" href="https://kingart.com.co/world/producto/proyectos-web-tiendas-online/" target="_blank"><span>¡Contacto!</span></a>
                        </div>
                    </div>
                </div>
            </div>
            {/* End PRicing Table Area  */}
        </div>
    )
}
export default PricingWeb;
