import PropTypes from "prop-types";
import React from 'react'
import { Helmet } from 'react-helmet';



const SEO = ( {title , description , keywords} ) => {
    return (
        <>
            <Helmet>
            <title>{title} || King Art - Agencia Web Bogota </title>
            <meta name="robots" content="noindex, follow" />
            <meta name="description" content="{description}" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta name="keywords" content="{keywords}" />
            </Helmet>
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.string
};

export default SEO;


