import React from 'react'
import SEO from "../../common/SEO";
import HeaderTopNews from '../../common/header/HeaderTopNews';
import HeaderOne from '../../common/header/HeaderOne';
import FooterFour from '../../common/footer/FooterFour';
import BrandThree from '../../elements/brand/BrandThree';
import AboutFour from '../../elements/about/AboutFour';
import ServiceOne from "../../elements/service/ServiceOne";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../../elements/counterup/CounterUpFour";
import TeamFour from "../../elements/team/TeamFour";
import TimelineDesign from "../../elements/timeline/TimelineDesign";
import Separator from "../../elements/separator/Separator";
import CircleProgress from "../../elements/progressbar/CircleProgress";

import DesignOne from '../../elements/about/DesignOne';
import DesignTwo from '../../elements/about/DesignTwo';
import DesignThree from '../../elements/about/DesignThree';
import PricingDesign from '../../elements/pricing/PricingDesign';




const LandingDesign = () => {
    return (
        <>
            <SEO title="Designing Digital" />
            <main className="page-wrapper">

                {/* Start Slider Area  */}
                <DesignOne /> 

                {/* End Slider Area  */}

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Diseño de Impacto"
                                    title = "Como ayudamos tu Marca?"
                                    description = "Tenemos experiencia en imagen corporativa y <strong>logos Bogotá</strong>, <br />ayudamos con piezas graficas a tu expandir tu marca."
                                    />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <TimelineDesign classVar="no-gradient"  />
                            </div>
                        </div>
    
                    </div>
                </div>
                {/* End Service Area  */}

                 <img className="w-100" src="art-1.jpg" alt="About Images" />   


                <DesignTwo /> 

 {/* Start Service Area  */}
                  <div className="rwt-elements-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--25">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Nuestra Experiencia "
                                    title = "10+ años en el campo de batalla"
                                    description = ""
                                />
                            </div>
                        </div>
                        <div className="col-lg-10 offset-lg-1 mt--10">
                            <CircleProgress />
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}

            
                {/* End Elements Area  */}
                <DesignThree /> 

               <div className="service-area rn-section-gapTop display-none">
                    <div className="container">
                        <PricingDesign />
                    </div>
                </div>

                <FooterFour />
            </main>
        </>
    )
}

export default LandingDesign;
