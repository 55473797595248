import React from 'react';
import Typed from 'react-typed';
import VideoOne from "../video/VideoOne";

const AppTwo = () => {
    return (
        <>
        <div className="rwt-about-area rn-section-gap back-white rn-splite-style">
            <div className="split-wrapper">
                <div className="container">               
                    <div className="row row--30 align-items-center">
                        <div className="col-lg-5">
                            <VideoOne />
                        </div>
                        <div className="col-lg-7 mt_md--40 mt_sm--40">
                            <div className="content split-inner">
                                <div className="section-title">
                                    <h2 className="title color-black">¿Que plataforma es perfecta para ti?  <br /> {" "}
                                        <span className="theme-gradient">
                                            Los alcances son Infinitos
                                        </span>
                                    </h2>
                                    <p>Nos<strong> presentas tus ideas</strong>, estudiamos tus necesidades, combinamos todo y creamos una mágica<strong> plataforma a tu medida</strong>, En<span className="branding-king">King Art</span> contamos con  habilidades de desarrollo que se destacan en<strong>  plataformas web, apps Android y Ios</strong> , Sistemas de Reservas, Sistema de Tickets,<strong>  E-commerce</strong> , etc... Con estructuras enfocadas a generar<strong>  ganancias y conversiones </strong> que disfrutan nuestros clientes.             </p>
                                    <div className="read-more-btn mt--40">
                                        <a className="btn-default" href="https://kingart.com.co/world/tienda/" target="_blank"><span>¡Contacto!</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
        </>
    )
}

export default AppTwo;   
