import React from 'react';
import Typed from 'react-typed';

const DesignThree = () => {
    return (
        <div className="rwt-about-area rn-section-gap back-white">
            <div className="container">
                <div className="row row--30 align-items-center">

                    <div className="col-lg-5 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title color-black">Todo Tipo de formatos<br /> {" "}
                                    <span className="theme-gradient">
                                        Publicidad Btl + Atl
                                    </span>
                                </h2>
                                <p> <strong>Logos En Bogotá</strong>, toda <strong>clase de piezas</strong> y <strong>manejos con tu imagen</strong>, Diseños Impactantes que atraerán<strong> tu audiencia</strong> y los acercara a<strong> tu producto</strong>, contamos con una amplia gama de productos que <strong>apoyaran tu proyecto </strong>y lo llevaran al siguiente nivel. </p>
                                 <div className="read-more-btn mt--40">
                                     <a className="btn-default" href="https://kingart.com.co/world/tienda/" target="_blank"><span>¡Contacto!</span></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-7">
                        <div className="thumbnail">
                            <img className="w-100" src="art-5.jpg" alt="About Images" />
                        </div>
                    </div>

                    
                </div>
            </div>   
        </div>
    )
}

export default DesignThree;   
