import React from 'react';
import Typed from 'react-typed';

const AppOne = () => {
    return (
        <div className="rwt-about-area back-white rn-splite-style">
            <div className=" split-wrapper">
                <div className="container">
                    <div className="row row--30 align-items-center">
                        <div className="col-lg-6 mt_md--40 mt_sm--40">
                            <div className="content split-inner">
                                <div className="section-title">
                                    <h2 className="title color-black">¡Todo comienza por una idea! <br /> {" "}
                                        <span className="theme-gradient">
                                            Desarrollo de Aplicaciones (apps)
                                        </span>
                                    </h2>
                                    <p>El mundo cambia y las herramientas evolucionan, Estudiamos y Convertimos tus ideas en <strong> Plataformas y Aplicaciones Digitales </strong>que beneficien tu empresa, generando conversiones y llevando tu proyecto al siguiente nivel. En  <span className="branding-king">King Art</span> apoyamos el proceso de planeacion de Tu <strong> plataforma Web</strong> , Diseño Web  identidad e interfaces,<strong>  Desarrollo de la aplicación ( app) </strong>  y Publicación en Google Play - App Store.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="thumbnail">
                                <img className="w-100 thumbnail image-rigth-content" src="aplicaciones-medellin-01.jpg" alt="About Images" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AppOne;   
