import React from 'react';
import { FiCheck } from "react-icons/fi";


const PricingDesign = () => {
    return (
        <div className="row row--0">

            {/* Start PRicing Table Area  */}
            <div className="col-lg-3 col-md-6 col-12">
                <div className="rn-pricing style-4">
                    <div className="pricing-table-inner">
                        <div className="pricing-header">
                            <h4 className="title">Plan Básico desde</h4>
                            <div className="pricing">
                                <div className="price-wrapper">
                                    <span className="currency">$</span>
                                    <span className="price">399K</span>
                                </div>
                                <span className="subtitle">Logo para empresa</span>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul className="list-style--1">
                                <li><FiCheck /> Entrega de Archivos</li>
                                <li><FiCheck /> Paleta de colores</li>
                                <li><FiCheck /> Tipografía</li>
                                <li><FiCheck /> Logo en Negativo</li>
                                <li><FiCheck /> Archivo en vector</li>
                                <li><FiCheck /> Archivos PNG en color y monocromático</li>
                                

                            </ul>
                        </div>
                        <div className="pricing-footer">
                            <a className="btn-default" href="https://kingart.com.co/world/tienda/diseno-branding-plan-basico/" target="_blank"><span>¡Contacto!</span></a>
                        </div>
                        
                    </div>
                </div>
            </div>
            {/* End PRicing Table Area  */}

            {/* Start PRicing Table Area  */}
            <div className="col-lg-3 col-md-6 col-12">
                <div className="rn-pricing style-4 active">
                    <div className="pricing-table-inner">
                        <div className="pricing-header">
                            <h4 className="title">Plan Estándar desde</h4>
                            <div className="pricing">
                                <div className="price-wrapper">
                                    <span className="currency">$</span>
                                    <span className="price">1.4M</span>
                                </div>
                                <span className="subtitle">Logo y usos</span>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul className="list-style--1">
                                <li><FiCheck /> Plan Básico +</li>
                                <li><FiCheck /> Tarjetas de presentación</li>
                                <li><FiCheck /> Hoja membrete</li>
                                <li><FiCheck /> 3 redes sociales</li>
                                <li><FiCheck /> Diseño textil</li>
                                <li><FiCheck /> Diseño folder</li>
                                <li><FiCheck /> Banner de facebook</li>

                            </ul>
                        </div>
                        <div className="pricing-footer">
                            <a className="btn-default" href="https://kingart.com.co/world/tienda/diseno-branding-plan-estandar-logo-y-usos/" target="_blank"><span>¡Contacto!</span></a>
                        </div>
                    </div>
                </div>
            </div>
            {/* End PRicing Table Area  */}

            {/* Start PRicing Table Area  */}
            <div className="col-lg-3 col-md-6 col-12">
                <div className="rn-pricing style-4">
                    <div className="pricing-table-inner">
                        <div className="pricing-header">
                            <h4 className="title">Plan Premiun</h4>
                            <div className="pricing">
                                <div className="price-wrapper">
                                    <span className="currency">$</span>
                                    <span className="price">4.9M</span>
                                </div>
                                <span className="subtitle">Manual de Marca </span>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul className="list-style--1">
                                <li><FiCheck /> Plan Estándar +</li>
                                <li><FiCheck /> Manual de identidad</li>
                                <li><FiCheck /> Variantes y usos de logo</li>
                                <li><FiCheck /> Packing para 1 producto</li>
                                <li><FiCheck /> Brochure triptico</li>
                                <li><FiCheck /> Diseño Aviso</li>
                                <li><FiCheck /> Diseño 1 promocional</li>
                            </ul>
                        </div>
                        <div className="pricing-footer">
                            <a className="btn-default" href="https://kingart.com.co/world/producto/diseno-branding-plan-premium-manual-de-marca/" target="_blank"><span>¡Contacto!</span></a>
                        </div>
                    </div>
                </div>
            </div>
            {/* End PRicing Table Area  */}

            {/* Start PRicing Table Area  */}
            <div className="col-lg-3 col-md-6 col-12">
                <div className="rn-pricing style-4">
                    <div className="pricing-table-inner">
                        <div className="pricing-header">
                            <h4 className="title">Piezas Graficas desde </h4>
                            <div className="pricing">
                                <div className="price-wrapper">
                                    <span className="currency">$</span>
                                    <span className="price">199K</span>
                                </div>
                                <span className="subtitle">USD Per Month</span>
                            </div>
                        </div>
                        <div className="pricing-body">
                            <ul className="list-style--1">
                                <li><FiCheck /> Banners Btl - Redes</li>
                                <li><FiCheck /> Manuales de marca </li>
                                <li><FiCheck /> Portafolios</li>
                                <li><FiCheck /> Brochure</li>
                                <li><FiCheck /> Identidad producto</li>
                                <li><FiCheck /> Papelería corporativa </li>
                            </ul>
                        </div>
                        <div className="pricing-footer">
                            <a className="btn-default" href="https://kingart.com.co/world/tienda/diseno-branding-plan-estandar-logo-y-usos/" target="_blank"><span>¡Contacto!</span></a>
                        </div>
                    </div>
                </div>
            </div>
            {/* End PRicing Table Area  */}
        </div>
    )
}
export default PricingDesign;
