import React from 'react'
import SEO from "../../common/SEO";
import HeaderTopNews from '../../common/header/HeaderTopNews';
import HeaderOne from '../../common/header/HeaderOne';
import FooterFour from '../../common/footer/FooterFour';
import BrandThree from '../../elements/brand/BrandThree';
import AboutFour from '../../elements/about/AboutFour';
import ServiceOne from "../../elements/service/ServiceOne";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../../elements/counterup/CounterUpFour";
import TeamFour from "../../elements/team/TeamFour";
import TimelineMarketing from "../../elements/timeline/TimelineMarketing";
import Separator from "../../elements/separator/Separator";

import MarketOne from '../../elements/about/MarketOne';
import MarketTwo from '../../elements/about/MarketTwo';
import MarketThree from '../../elements/about/MarketThree';
import PricingMarket from '../../elements/pricing/PricingMarket';




const LandingMarketing = () => {
    return (
        <>
            <SEO title="Marketing Digital" />
            <main className="page-wrapper">

                {/* Start Slider Area  */}
                <MarketOne /> 

                {/* End Slider Area  */}

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Marketing Digital para Todos "
                                    title = "Te ayudamos en tus campañas "
                                    description = "Somos el socio ideal para tus<strong> campañas digitales </strong>que <strong>buscan vender tus productos</strong> <br />  o hacerte conocer en tu <strong>nicho de clientes</strong>, por medio de las<strong> herramientas digitales</strong> y <strong>redes sociales</strong>."
                                    />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <TimelineMarketing classVar="no-gradient"  />
                            </div>
                        </div>
    
                    </div>
                </div>
                {/* End Service Area  */}

                <MarketThree /> 

                {/* Start Service Area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">Que resultados puedes conseguir con nuestras campañas de <strong>Marketing Bogotá</strong>?</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">En las campañas se busca conversión, Que <strong>tus clientes te vean</strong>, te conozcan, busca que los que te estan<strong> buscando te encuentren</strong>.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}
                <MarketTwo /> 

                {/* End Elements Area  */}

               <div className="service-area rn-section-gapTop display-none">
                    <div className="container">
                        <PricingMarket />
                    </div>
                </div>

                

                






                <FooterFour />
            </main>
        </>
    )
}

export default LandingMarketing;
