import React, { useState, useEffect } from "react";
import axios from 'axios';
import BlogListTwo from './BlogListTwo';
import { FaSpinner } from "react-icons/fa";


const BlogPropTwo = ({ column , StyleVarProp}) => {
    const [getAllItems, setGetAllItems] = useState([]);
    const [idPost, setIdPost] = useState();
    const [numberPage, setNumberPage] = useState(4);
    const [dataVisibleCount, setDataVisibleCount] = useState();
    const [dataIncrement] = useState(3) ;
    const [noMorePost, setNoMorePost] = useState(false);
    const [activeFilter, setActiveFilter] = useState("");
    const [visibleItems, setVisibleItems] = useState([]);

     useEffect(() => {
        const getForecast = async () => {   
            const url = `https://kingart.com.co/world/wp-json/wp/v2/posts`;
   
            try {
                const { data } = await axios.get(url);                
                setGetAllItems(data)
                setIdPost(true);
                console.log(data[0].index);    
                } catch (error) {
                    console.log(error)            
            }
        }
        getForecast();


        }, [idPost]); 

       

 
    return (
        <>
          <div className="col-lg-12">
                   <div className="row row--15">
                    {getAllItems.map((item) => (
                        <div key={item.id} className={column}>
                        </div>
                    ))}
                </div>
            </div>
           

        </>
    )
}
export default BlogPropTwo;