import React , { useState } from 'react';
import {AnimatePresence, motion, useScroll} from "framer-motion/dist/framer-motion";

import SectionTitle from "../sectionTitle/SectionTitle";

const animation = {
 hidden: {
    fill: "rgba(255,255,255,0)", 
    stroke: "0,0,0,0)",
    opacity: 0,
    pathLength: 1,
    background: "rgba(0,0,0,.0)",

  },
  initialb: {
    stroke: "rgba(255,255,255,0)",
    opacity: 1,
    pathLength: 1,
    background: "rgba(0,0,0,.0)",

  },
  hoverba: {
    stroke: "rgba(255,255,255,.8)",
    opacity: 1,
    background: "rgba(0,0,0,.9)",
    transition: {
          background: { duration: .5, ease: "easeInOut"  },
      }
 }
      ,

  hoverbb: {
    fill: "rgba(255, 255, 255, 0.7)",
    stroke: "rgba(0, 0, 0, 0.7)",
    opacity: 1,
    strokeWidth:1,

    background: "rgba(0,0,0,.9)",
    transition: {
          background: { duration: .5, ease: "easeInOut"  },
      }
  } ,
  initialbb: {
    fill: "rgba(255, 255, 255, 0.1)",
    stroke: "rgba(255,255,255, 0.9)",
    opacity: .9,
    strokeWidth:3,
    pathLength: 1,
    background: "rgba(0,0,0,0)",

  },
      initiali: {
            stroke: "rgba(255,255,255,0)",
    opacity: 0,
    

  },
  hoveri: {
    opacity: 1,
    transition: {
          opacity: { duration: .5, ease: "easeInOut"  },
      }
  },
   hoverip: {
    opacity: 1,
   rotate:180,
    transition: {
          opacity: { duration: .9, ease: "easeInOut"  },
          rotate: { duration: 2, ease: "easeInOut"  },
      }
  },
   hoverbap: {
    opacity: 0.04,
    transition: {
          opacity: { duration: .9, ease: "easeInOut"  },
      }
 }
};

const ItemPortfolio = ({value}) => {
  const [isHovered, setIsHovered] = useState(false);
  function handleMouseEnter() {
    setIsHovered(true);
  }

  function handleMouseLeave() {
    setIsHovered(false);
  }

  return (
    
          <div className="grid" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <motion.div 
             variants={animation}
               className="back-ports"
               animate={isHovered ? "hoverba" : "initialb"}>      
              
          </motion.div>
          <motion.img 
          variants={animation}
            className="back-item-port" 
            src="./portfolio-item.svg" 
            animate={isHovered ? "hoverip" : "hoverbap"}
          />
          {value === "DISEÑO UI/IX" && (
          <a target="_blank" href="https://kingart.com.co/world/category/diseno-ui-ux/ ">

             <motion.svg viewBox="0 0 174.05 178.61" x="0px" y="0px" className="icon1" data-name="Layer 1" width="100%" height="100%"xmlns="http://www.w3.org/2000/svg" >
              <motion.path 

                variants={animation}
                animate={isHovered ? "hoverbb" : "initialbb"}
                className="" d="M170.82,89.85c0,46.34-37.57,83.91-83.91,83.91S3,136.19,3,89.85,40.57,5.94,86.91,5.94s83.91,37.57,83.91,83.91Zm-167.82,0H170.82M86.91,5.94V173.76m59.33-24.58L27.58,30.52m118.66,0L27.58,149.18M145.93,30.83H27.89v118.04h118.04V30.83Zm24.45,59.02L86.91,6.38,3.44,89.85l83.47,83.47,83.47-83.47Z"/>
            </motion.svg>
            </a>

            )}
            {value === "BRANDING" && (
                      <a target="_blank" href="https://kingart.com.co/world/category/diseno-web/">

             <motion.svg viewBox="0 0 174.05 178.61" x="0px" y="0px" className="icon1" data-name="Layer 1" width="100%" height="100%"xmlns="http://www.w3.org/2000/svg" >
              <motion.path variants={animation}
                animate={isHovered ? "hoverbb" : "initialbb"}
                className="" d="M86.41,175.71L2,91.3,86.41,6.9l84.41,84.41-84.41,84.41Zm42.02-126.42H44.39v84.03h84.03V49.29ZM86.41,6.9V174.57M2,91.3H170.82M44.39,49.29l84.03,84.03m-84.03,0L128.42,49.29m-41.77,.68c-23.07,0-41.77,18.7-41.77,41.77s18.7,41.77,41.77,41.77,41.77-18.7,41.77-41.77-18.7-41.77-41.77-41.77Z"/>
            </motion.svg>
            </a>
            )}
            {value === "MARKETING" && (
                      <a target="_blank" href="https://kingart.com.co/world/category/marketing-digital/">

             <motion.svg viewBox="0 0 174.05 178.61" x="0px" y="0px" className="icon1" data-name="Layer 1" width="100%" height="100%"xmlns="http://www.w3.org/2000/svg" >
              <motion.path variants={animation}
                animate={isHovered ? "hoverbb" : "initialbb"}
                className="" d="M86.03,3.69l74.52,129.07H11.51L86.03,3.69ZM49.97,110.55H122.08l-36.05-62.44-36.05,62.44Zm15.24-56.87V125.79l62.44-36.05-62.44-36.05Zm56.87,15.24H49.97l36.05,62.44,36.05-62.44Zm-15.24,56.87V53.68l-62.44,36.05,62.44,36.05Zm0,0V53.68l-62.44,36.05,62.44,36.05Zm53.7-79.07H11.51L86.03,175.78,160.54,46.72Zm-74.52,1.03c-23.19,0-41.99,18.8-41.99,41.99s18.8,41.99,41.99,41.99,41.99-18.8,41.99-41.99-18.8-41.99-41.99-41.99Z"/>
            </motion.svg>
            </a>
            )}
            {value === "FRONT END" && (
            <a target="_blank" href="https://kingart.com.co/world/category/programacion/">
             <motion.svg viewBox="0 0 174.05 178.61" x="0px" y="0px" className="icon1 icones" data-name="Layer 1" width="100%" height="100%"xmlns="http://www.w3.org/2000/svg" >
              <motion.path variants={animation}
                animate={isHovered ? "hoverbb" : "initialbb"}
                className="" d="M87.24,174.46L28.72,115.94,87.24,2.94l58.52,113-58.52,58.52Zm0-72.51c-16.63,0-30.11,13.48-30.11,30.11s13.48,30.11,30.11,30.11,30.11-13.48,30.11-30.11-13.48-30.11-30.11-30.11Zm0,30.11l-58.52-16.12m117.04,0l-58.52,16.12m0,42.4v-42.4m0-129.12V132.07m19.88-90.74h-39.76l19.88,60.63,19.88-60.63ZM35.83,101.95h102.83m-51.41,30.11l-51.41-30.11m102.83,0l-51.41,30.11"/>
            </motion.svg>
            </a>

            )}
        <motion.h2  
        variants={animation}
        initial="hidden"
        animate={isHovered ? "hoveri" : "initiali"} 
        className="text-item"
        >
            <SectionTitle
                    textAlign = "text-center"
                    radiusRounded = ""
                    subtitle = {value}
                    title = ""
                     description = ""
                    />
          </motion.h2>
                    
            </div>
        
  );
}

export default ItemPortfolio;