import React from 'react'
import SEO from "../../common/SEO";
import HeaderTopNews from '../../common/header/HeaderTopNews';
import HeaderOne from '../../common/header/HeaderOne';
import FooterFour from '../../common/footer/FooterFour';
import BrandThree from '../../elements/brand/BrandThree';
import AboutFour from '../../elements/about/AboutFour';
import ServiceOne from "../../elements/service/ServiceOne";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../../elements/counterup/CounterUpFour";
import TeamFour from "../../elements/team/TeamFour";
import TimelineApp from "../../elements/timeline/TimelineApp";
import Separator from "../../elements/separator/Separator";

import AppOne from '../../elements/about/AppOne';
import AppTwo from '../../elements/about/AppTwo';
import AppThree from '../../elements/about/AppThree';
import AppFowr from '../../elements/about/AppFowr';

import PricingApp from '../../elements/pricing/PricingApp';




const LandingApp = () => {
    return (
        <>
            <SEO title="Apping Digital" />
            <main className="page-wrapper">

                {/* Start Slider Area  */}
                <AppOne /> 

                {/* End Slider Area  */}

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Que te ofrecemos en King Art?"
                                    title = "De lo Simple a lo Grande"
                                    description = "En King Art manejamos metodologías de investigacion y procesos actuales en el mercado,<br /> asi llegamos a reconocer que tipo de  plataforma es perfecta para tu proyecto."
                                    />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <TimelineApp classVar=""  />
                            </div>
                        </div>
    
                    </div>
                </div>
                {/* End Service Area  */}

                

                <AppTwo /> 

 {/* Start Service Area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">En King Art nos comprometemos a desarrollar tus ideas, Apps funcionales y que venden .</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">Construimos aplicaciones a medida, ecommerce, Erp Contables, Conexiones Api y webservices, para toda clase de dispositivos Pcs,Tvs, Mobil… toda clase de plataformas y soluciones para tu proyecto.Nos apoyamos de un equipo versátil y con las ultimas tecnologías para aplicaciones combinada con marketing para asegurar la conversión  .</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}

                
                {/* Start Elements Area  */}
                <div className="rwt-counterup-area pb--100">
                    <div className="container mt_dec--30">
                         
                    </div>
                </div>
                {/* End Elements Area  */}
                <AppThree /> 

               <div className="service-area rn-section-gapTop display-none">
                    <div className="container">
                        <PricingApp />
                    </div>
                </div>

                 <AppFowr /> 


                






                <FooterFour />
            </main>
        </>
    )
}

export default LandingApp;
