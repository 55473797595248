import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
    {
        id: "1",
        title: "Logos",
        description: "Logotipos, Logos Símbolos, logos, con tu identidad. ",
    },
    {
        id: "2",
        title: "Logos y Tratamiento ",
        description: "Tu logo con su respectivos usos.",
    },
    {
        id: "3",
        title: "Manual de marca ",
        description: "Un paquete de identidad corporativa, impulsa tu marca.",
    },
    {
        id: "4",
        title: "Piezas Graficas",
        description: "Piezas graficas para BTL o Web cualquier formato.",
    },
]

const TimelineApp = ({classVar}) => {
    return (
        <div className="timeline-style-two bg-color-blackest">
            <div className="row row--0">
                {/* Start Single Progress */}
                {TimelineData.map((data , index) =>(
                    <div className={`col-lg-3 col-md-3 rn-timeline-single ${classVar}`} key={index}>
                        <div className="rn-timeline">
                           
                                <h6 className="title">{data.title}</h6>
                            <div className="progress-line">
                                <div className="line-inner"></div>
                            </div>
                            <div className="progress-dot">
                                <div className="dot-level">
                                    <div className="dot-inner"></div>
                                </div>
                            </div>
                        
                                <p className="description">{data.description}</p>
                        </div>
                    </div>
                ))}
                {/* End Single Progress */}
            </div>
        </div>
    )
}
export default TimelineApp;
