import React from 'react';
import Typed from 'react-typed';

const WebOne = () => {
    return (
        <div className="rwt-about-area back-white vh100 mxw">
            <div className="container ">
                <div className="row row--30 align-items-center ">

                    <div className="col-lg-5 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title color-black">Diseño Ui Ux <br /> {" "}
                                    <span className="theme-gradient">
                                        Usabilidad para Todos
                                    </span>
                                </h2>
                                <p> <strong>Diseño Ui / Ux</strong> basado en la usabilidad, entornos gráficos con líneas de manejo claras,<strong> navegaciones intuitivas</strong> siguiendo las reglas de<strong> cada plataforma</strong>, combino diseños de vanguardia, conceptos actuales, para todo tipo de público y todo tipo de producto. Investigación, objetivos, prototipos, Layouts interactivos, testing para creación de productos. </p>
                                  
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-7 ">
                        <div className="thumbnail">
                            <img className="w-100" src="design-111.jpg" alt="About Images" />
                        </div>
                    </div>

                    
                </div>
            </div>   
        </div>
    )
}

export default WebOne;   
