import React from 'react';
import Typed from 'react-typed';

const DesignOne = () => {
    return (
        <div className="rwt-about-area back-white vh100">
            <div className="container ">
                <div className="row row--30 align-items-center vh100">

                    <div className="col-lg-5 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title color-black">¡Design`s Times! <br /> {" "}
                                    <span className="theme-gradient">
                                        Imagen Corporativa  
                                    </span>
                                </h2>
                                <p> <strong>Nuestra marca</strong> es <strong>nuestra identidad</strong>, habla sobre nuestra forma de ser y sobre nuestro carácter, en ello la importancia del trato y la<strong> difusión correcta</strong>, entregar el mensaje que se desea, <strong>Impactar al receptor</strong>, no solo generar una conversión, también ganar una <strong>compra recurrente</strong>, manejar todos los medios para lograr nuestro objetivo, te apoyamos en la <strong>creación de tu marca</strong>,<strong> logo</strong>, <strong>piezas graficas</strong> para <strong>BTL o Web</strong>, tu <strong>imagen corporativa Bogotá </strong>.  </p>
                                 <div className="read-more-btn mt--40">
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-7 vh100">
                        <div className="thumbnail">
                            <img className="w-100" src="art-3.jpg" alt="About Images" /> 
                            <img className="w-100" src="art-2.jpg" alt="About Images" />
                        </div>
                    </div>

                    
                </div>
            </div>
        </div>
    )
}

export default DesignOne;   
