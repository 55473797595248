import React, { useEffect , useState } from 'react';
import {slugify} from "../../../utils";
import axios from 'axios';
import renderHTML from 'react-render-html';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";


const BlogListhome = () => {
    
    const [posts, setPosts] = useState([]);
    

useEffect(() => {
    const GetList = async () => {
        const wordPressSiteURL = `https://kingart.com.co/world/`;
        try {
            const { data } = await axios.get(`${wordPressSiteURL}/wp-json/wp/v2/posts/?_embed&per_page=5` ); 
            setPosts(data);
        } catch (error) {
            console.log(error)            
        }
    }
    GetList();

    }, [posts])   
   

    return (
        <>
            <div className="content-lasted">
                { posts.map( post => (
                <div key={post.id} className="item-lasted">
                    <div  className="rn-card" >
                    <Link to={{pathname:`${post.link}`}} target="_blank" className="image">
                        <div className="inner">
                            <div className="thumbnail">
                                    <img src={post._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url} alt="Blog Image" />  
                            </div>
                            <div className="content">
                                <span className="theme-gradient">
                                    
                                        {renderHTML( post.title.rendered )}
                                    
                                </span>
                            </div>
                        </div>
                    </Link>    
                    </div>
                </div>
             ) ) }
             </div>
        </>
    );
 };


export default BlogListhome;
