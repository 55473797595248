import React from 'react';
import { FiCheck } from "react-icons/fi";


const PricingMarket = () => {
    return (
        <div className="row">
            <div className="col-lg-6 offset-lg-3">
                <div className="row row--0">
                    {/* Start PRicing Table Area  */}
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="rn-pricing style-2">
                            <div className="pricing-table-inner">
                                <div className="pricing-header">
                                    <h4 className="title">Campaña SEO
 Desde</h4>
                                    <div className="pricing">
                                        <div className="price-wrapper">
                                            <span className="currency">$</span>
                                            <span className="price">400K</span>
                                        </div>
                                        <span className="subtitle">Por mes de Campaña</span>
                                    </div>
                                </div>
                                <div className="pricing-body">
                                    <ul className="list-style--1">
                                        <li><FiCheck /> Google Analytics </li>
                                        <li><FiCheck /> Palabras clave</li>
                                        <li><FiCheck /> Trafico Web</li>
                                        <li><FiCheck /> Motores de Búsqueda</li>
                                        <li><FiCheck /> Optimizar contenido  </li>
                                    </ul>
                                </div>
                                <div className="pricing-footer">
                                    <a className="btn-default" href="https://kingart.com.co/world/producto/social-media-campana-seo/" target="_blank"><span>¡Contacto!</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End PRicing Table Area  */}

                    {/* Start PRicing Table Area  */}
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="rn-pricing style-2 active">
                            <div className="pricing-table-inner">
                                <div className="pricing-header">
                                    <h4 className="title">Campaña SEM desde </h4>
                                    <div className="pricing">
                                        <div className="price-wrapper">
                                            <span className="currency">$</span>
                                            <span className="price">800K </span>
                                        </div>
                                        <span className="subtitle">USD Per Month</span>
                                    </div>
                                </div>
                                <div className="pricing-body">
                                    <ul className="list-style--1">
                                        <li><FiCheck /> Google Adwords </li>
                                        <li><FiCheck /> Estudio de Publico</li>
                                        <li><FiCheck /> Segmentación </li>
                                        <li><FiCheck /> Medicion de datos </li>
                                        <li><FiCheck /> Campaña Branding</li>
                                        <li><FiCheck /> Trafico guiado</li>
                                    </ul>
                                </div>
                                <div className="pricing-footer">
                                    <a className="btn-default" href="https://kingart.com.co/world/producto/social-media-campana-sem/" target="_blank"><span>¡Contacto!</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End PRicing Table Area  */}
                </div>
            </div>
        </div>
    )
}
export default PricingMarket;
